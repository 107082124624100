.dark-button-tooltip-arrow > .MuiTooltip-arrow {
    /* color: var(--confirm-background-color) !important; */
    color: var(--button-color) !important;
}

.MuiTooltip-tooltip.dark-button-tooltip {
    /* background-color: var(--confirm-background-color) !important; */
    background-color: var(--button-color) !important;
    margin-right: 24px !important;
    font-size: 14px !important;
    padding: 12px 16px !important;
    border-radius: 5px !important;
}

.MuiTooltip-tooltip.dark-button-tooltip:hover { 
    /* background-color: var(--confirm-background-color) !important; */
    background-color: var(--button-confirm-color) !important;
}

.MuiTooltip-tooltip.dark-button-tooltip:hover > .MuiTooltip-arrow { 
    /* background-color: var(--confirm-background-color) !important; */
    color: var(--button-confirm-color) !important;
}
