
ul.integration-items { 
    
}

ul.integration-items li {
    margin-bottom: 32px;
    background-color: var(--dark-background-color);
    border-radius: 16px;
    border: var(--border-color) 1px dashed;
    padding: 24px;
}

ul.integration-items li strong { 
    display: block;
    margin-top: 8px;
    font-weight: normal;
    color: var(--text-color-faded);
}

ul.integration-items li > h2 {
    margin: 0 0 8px 0;
    display: flex;
    justify-content: space-between;
}

ul.integration-items li > h2 > span {
    display: inline-block;
}

ul.integration-items li > h2 > span:first-child { 
    margin-right: 16px;
}

ul.integration-items li > h2 > span.name { 
    width: 100%;
}

ul.integration-items li > h3 {
    margin: 0 0 8px 0;
    display: flex;
    justify-content: space-between;
}

ul.integration-items li > p {
    margin: 0;
}

li a.connect,
li p > a.connect {
    margin-top: 16px;
    padding: 10px 16px;
    background-color: var(--dark-background-color);
    border-radius: 8px;
    display: inline-block;
    color: var(--bocg-blue);
}

li a.connect:hover,
li p > a.connect:hover {
    color: white;
}

li a.connect svg {
    margin-left: 12px;
}


li .connected,
li p > .connected {
    margin-top: 16px;
    padding: 10px 16px;
    background-color: var(--main-column-background-color);
    border-radius: 8px;
    display: inline-block;
    color: var(--connected-green-faded);
    cursor: default;
}

li .connected:hover,
li p > .connected:hover {
    color: var(--connected-green);
}

li .connected svg {
    margin-right: 8px;
}

input#qr-data { 
    width: 520px;
}

input#qr-data::placeholder { 
    color: var(--text-color-faded);
}
