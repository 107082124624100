
td span.user-type { 
    font-size: 11px;
    display: inline-block;
    margin: 0 0 0 8px;
    padding: 3px 6px;
    border-radius: 4px;
    background-color: var(--light-border-color);
}

td span.user-type.user-type-0 { 
    display: none;
}

td span.user-type.user-type-1 {
}

td span.user-type.user-type-2 {
    background-color: var(--yellow-faded);
}

td span.user-type.user-type-3 {
}

td span.user-type.user-type-4 {
}

div.auth-users { 
    margin-top: 48px;
}