table.x-large tr td.url { 
    font-size: 11px;
    white-space: nowrap;
}

div.short-url-form { 
    width: 500px;
}

div.short-url-form div input[type="text"] { 
    font-size: 16px;
}

.short-url-form label { 
    display: block;
}

.short-url-form div #short-url-path {
    width: 140px;
    white-space: nowrap;
}

.short-url-form div.url { 
    color: var(--text-color-faded);
    letter-spacing: 1px;
}

.short-url-form div #redirect-url { 
    width: 100%;
    white-space: nowrap;
}
