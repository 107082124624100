div.activity-table { 
    margin-bottom: 32px;
}

table.activity { 
    width: 100%;
}

table.activity tr td {
    font-size: 0.85vw;
    white-space: nowrap;
}

table.activity tr td > svg,
table.activity tr td > a > svg { 
    margin-right: 12px;
}

.small table.activity tr td:first-child,
.small table.activity tr th:first-child { 
    width: 70%;
}

.small > table.activity tr td:last-child,
.small > table.activity tr th:last-child {
    width:30%;
}


table.activity td.text,
table.activity th.text {
    width: 65%;
}

table.activity td.level,
table.activity th.level {
    width: 15%;
}

table.activity td.date,
table.activity th.date {
    width: 20%;
}

table.activity span.updated-by-user { 
    display: inline-block;
    font-size: 11px;
    padding: 0;
    margin-left: 16px;
}

table.activity tr > td > svg + span.updated-by-user {
    margin-left: 0 !important;
    margin-right: 8px;
}

table.activity span.updated-by-user > a {
    border-radius: 5px;
    background-color: var(--badge-color);
    color: var(--badge-text-color);
    padding: 6px 12px;
    display: inline-block;
}

table.activity span.updated-by-user > a:hover { 
    color: var(--badge-hover-color);
}

table tr.error-tr > td { 
    overflow: hidden;
    color: var(--text-error-color);
}

table tr.warning-tr>td { 
    color: var(--text-warning-color);
}

table tr.error-tr > td:first-child { 
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    width: 50%;
}

table tr.error-tr>td:first-child svg { 
    color: var(--error-code-color);
}

table tr.error-tr > th:first-child {
    width: 50%;
}

table tr.error-tr > *:last-child { 
    width: 20%;
}

table.column-adjust tr > th:empty,
table.column-adjust tr > td:empty { 
    display: none;
}

table tr > td span.entity-log {
    font-size: 11px;
    display: inline-block;
    margin: 0 0 0 8px;
    padding: 3px 6px;
    border-radius: 4px;
    background-color: var(--light-border-color);
}

table td .small-date { 
    font-size: 0.85rem;
}

table tr.error-tr:hover td { 
    /* */
    opacity: 0.55;
}

table tr.error-tr:hover td:first-child { 
    display: table-cell;
    overflow: unset;
    white-space: unset;
    opacity: 1.0;
    width: 100%;
}

.dialog-system-error-content { 
    display: block;
}

.system-error-body { 
    background-color: black;
    position: relative;
    border-radius: 8px;
    display: block;
    min-width: 800px;
}

.system-error-body > pre {
    padding: 24px;
    display: block;
    white-space: pre-wrap;
    word-break: break-word;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--code-font);
    color: var(--error-code-color);
}

.system-error-user-agent { 
    display: block;
    margin-bottom: 12px;
}

.dialog-system-error-content .system-error-header {
    background-color: var(--main-column-background-color);
    display: inline-block;
    font-size: 14px;
    padding: 4px 8px !important;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
    white-space: nowrap;
}

.dialog-system-error-content .system-error-header:hover {
    background-color: var(--dark-background-color);
}
