span.search-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    padding: 16px 12px 16px 16px;
    border-radius: 12px;
    background-color: #FFFFFF08;
    font-size: 16px;
    font-weight: normal;
}

span.search-filter.show {
    padding: 8px 12px 8px 16px;
}

h2 span.search-filter { 
    margin-left: 32px;
}

h3.has-search { 
    display: flex;
    justify-content: space-between;
    align-items: center;
}

h3.has-search span {
    white-space: nowrap;
}

h3.has-search > span.icon { 
    width: 100%;
}

h3.has-search > span > span.search-filter {
    margin-left: 32px;
}

span.search-filter > span { 
    white-space: nowrap;
}

span.search-filter > a {

}

span.search-filter > span.search-filter-input {
    display: none;
}

span.search-filter.show > span.search-filter-input {
    display: inline-block;
}


span.search-filter > span.search-filter-input {

}

span.search-filter > span.search-filter-input input {
    margin-right: 12px;
    margin-left: 12px;
}

span.search-filter > span.search-filter-input input::placeholder {
    color: #666666;
}

span.search-filter > span.search-filter-input a {

}

.details-body p.with-filter > span.search-filter { 
    float: right;
}