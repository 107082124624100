.date-range-selector { 
    
}

.date-range-selector > .date-range-inputs { 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    gap: 8px;
}
