.line-graph { 
    min-height: 256px;
    min-width: 300px;
}

.line-graph > .line-graph-body { 
    border: var(--border-color) 1px solid;
    padding: 8px;
    border-radius: 12px;
}

