ul.calendar-list { 
    
}

ul.calendar-list > li { 
    margin-bottom: 12px;
    padding: 12px;
    border: var(--border-color) 1px dashed;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
}

ul.calendar-list > li > span > span {
    display: block;
}

ul.calendar-list > li.date-title {
    color: var(--bocg-blue);
    font-size: 1.1em;
    font-weight: normal;
    margin-top: 16px;
}

ul.calendar-list > li.today { 
    border-color: var(--bocg-blue-faded);
    background-color: #22222288;
}

ul.calendar-list > li.date-title:first-child { 
    margin-top: 0;
}

ul.calendar-list > li > span > span:first-child {
    padding-bottom: 8px;
}

ul.calendar-list > li span.time { 
    color: var(--text-color-faded);
    font-size: 0.9em;
}

div.calendars > span { 
    display: inline-block;
    border: var(--bocg-blue-faded) 1px solid;
    padding: 6px 10px;
    border-radius: 5px;
}

div.calendars > span > span { 
    display: flex;
    justify-content: center;
    color: var(--text-color-faded);
}

div.calendars > span.selected > span {
    display: flex;
    justify-content: center;
    color: var(--bocg-blue);
}

.calendar.loading-box { 
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-left: 32px;
}

.calendar.loading-box > label:first-child { 
    padding-right: 12px;
    display: inline-block;
}