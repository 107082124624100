body.light {
    font-size: 16px;
    color: black;
    background-color: #FFF;
    height: 100%;
    display: block;
}


body.light h1 { 
    color: black;
}

body.light span input,
body.light span input[type="date"] {
    border-color: #DDD;
    margin-bottom: 0;
    border-width: 1px;
    background-color: white;
    color: black;
}

body.light h1.flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

body.light h1 span { 
    margin-left: 24px;
}

body.light h1 span button { 
    background-color: #000055;
    color: white;
}

body.light #light-master-screen > h1 { 
    padding: 12px;
}

body.light .roadmap-document-viewer > .roadmap-document-column > .roadmap-document-column-header { 
    background-color: #EEE;
    color: black;
}

body.light .roadmap-document-viewer > .roadmap-document-column > .roadmap-document-column-header { 
    color: #0000AA;
}

body.light .roadmap-document-viewer > .roadmap-document-column.days .roadmap-document-column-day.cell {
    /* background-color: #00000050; */
    font-size: 20px;
}

body.light .roadmap-document-viewer > .roadmap-document-column.project .roadmap-document-column-item.cell.project-item { 
    background-color: #EEF;
}

body.light .roadmap-document-viewer {
    border-top: #CCC 1px solid;
    border-left: #CCC 1px solid;
}

body.light .roadmap-document-viewer .roadmap-document-column {
    border-right: #CCC 1px solid;
}

body.light .roadmap-document-viewer > .roadmap-document-column .cell > span {
    padding: 8px;
    border-bottom: #CCC 1px solid;
}

body.light .roadmap-document-viewer > .roadmap-document-column.project .roadmap-document-column-item.cell > span {
    max-width: unset;
}

body.light .roadmap-document-column.days .roadmap-document-column-day.cell span.day-cell-day { 
    color: #555;
    text-shadow: 0 0 1px #FFF;
}

body.light .roadmap-document-viewer > .roadmap-document-column.days .roadmap-document-column-day.cell { 
    background-color: #EEE;
}

body.light .roadmap-document-column.days .roadmap-document-column-day.cell span.day-cell-date label { 
    color: #888;
}

.light .roadmap-document-viewer > .roadmap-document-column.project .roadmap-document-column-item.cell > span > label.selected { 
    color: blue;
}

body.light .MuiTooltip-arrow {
    color: #00000000 !important;
}

body.light .MuiTooltip-tooltip {
    background-color: #00000000 !important;
    padding: 0 !important;
    margin: 0 !important;
}

body.light div.user-badge {
    background-color: #005;
}
