
/* Mobile CSS */
@media only screen and (max-width: 1400px) {
    .jumbo-list > div > ul > li {
        font-size: 0.8em;
    }
    
}

@media only screen and (max-width: 600px) {
    .mobile {
        display: block;
    }
    
    .desktop {
        display: none;
    }

    .jumbo-list > div > ul > li { 
        
    }
}
