
.view-selector { 
    display: inline-block;
}

.view-selector span {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
}

.view-selector span a {
    display: inline-block;
    padding: 5px 12px;
    border-radius: 5px;
}

.view-selector span a svg { 
    width: 16px;
    height: 16px;
    color: var(--text-color);
}

.view-selector span a:hover {
    background-color: var(--dark-background-color);
}

.view-selector span a:hover svg,
.view-selector span a.selected svg {
    color: var(--bocg-blue);
}

.view-selector span a.selected {
    color: var(--bocg-blue);
    background-color: var(--dark-background-color);
}
