.roadmap-calendar-container { 
    overflow-x: scroll;
}

.roadmap-viewer {
    display: block;
    position: relative;
    overflow-x: scroll;
    min-height: 320px;
    scrollbar-width: none;
    white-space: nowrap;
}

.roadmap-viewer::-webkit-scrollbar {
    width: 0;
    display: none;
}

.roadmap-calendar-row,
.roadmap-timeline-header {
    display: block;
    width: inherit;
    white-space: nowrap;
}

.roadmap-timeline-header > .cell {
    background-color: var(--dark-background-color);
    display: inline-block;
    border-left: var(--light-border-color) solid 1px;
    border-bottom: var(--light-border-color) solid 1px;
}

.roadmap-timeline-header.weeks-header > .cell:last-child,
.roadmap-timeline-header.days-header > .cell:last-child { 
    border-right: var(--light-border-color) solid 1px;;
}

.roadmap-timeline-header.weeks-header > .cell {
    border-top: var(--light-border-color) solid 1px;
}

.roadmap-calendar-row { 
    margin-bottom: 4px;
    display: block;
    white-space: nowrap;
}

.roadmap-calendar-row .empty-roadmap-cell {
    display: inline-block;
    background-color: transparent;
    border: #CCCCCC11 solid 1px;
    height: 32px;
    font-size: 12px;
}

.roadmap-calendar-row .empty-roadmap-cell > span {
    display: block;
    padding: 8px 12px;
}

.roadmap-calendar-row .roadmap-cell {
    display: block;
    /* background-color: #0022AA11; */
    border: var(--light-border-color) solid 1px;
    font-size: 12px;
    white-space: nowrap;
    height: 32px;
}

.roadmap-calendar-row .roadmap-cell.completed {
    background-color: #FFFFFF08;
}

.roadmap-calendar-row .roadmap-cell.completed a svg { 
    margin-right: 8px;
    color: var(--good-green);
    opacity: 0.3;
}

.roadmap-calendar-row .roadmap-cell.completed a {
    color: #CCCCCC33;
}

.roadmap-calendar-row .roadmap-cell.completed a:hover { 
    color: var(--link-color);
}

.roadmap-calendar-row .roadmap-cell.completed a:hover svg {
    opacity: 1.0;
}

.roadmap-calendar-row .roadmap-cell.blocked {
    background-color: #AA000011;
}

.roadmap-calendar-row .roadmap-cell { 
    
}

.roadmap-calendar-row .roadmap-cell * {
    white-space: nowrap;
}

.roadmap-calendar-row .cell {
    display: inline-block;
}

.roadmap-calendar-row:first-child .cell {
    display: inline-block;
}

.roadmap-viewer .cell > a { 
    display: block;
    padding: 8px 12px;
}

.roadmap-project-header-row { 
    display: block;
    width: 100%;
    min-width: 100%;
    margin-bottom:3px;
    margin-top: 8px;
    font-size: 11px;
    background-color: #00000033;
}

.roadmap-project-header-row > a { 
    display: block;
    width: 100%;
    min-width: 100%;
    padding: 5px 12px;
}

.roadmap-project-header-row.row0 { 
    margin-top: 1px !important;
}

.cell.roadmap-cell.roadmap-cell-item-0 a { background-color: #0000FF08; }
.cell.roadmap-cell.roadmap-cell-item-1 a { background-color: #00FF0008; }
.cell.roadmap-cell.roadmap-cell-item-2 a { background-color: #FFFF0008; }
.cell.roadmap-cell.roadmap-cell-item-3 a { background-color: #FF00FF08; }
.cell.roadmap-cell.roadmap-cell-item-4 a { background-color: #FF000008; }
.cell.roadmap-cell.roadmap-cell-item-5 a { background-color: #0000FF08; }
.cell.roadmap-cell.roadmap-cell-item-6 a { background-color: #0000FF08; }
.cell.roadmap-cell.roadmap-cell-item-7 a { background-color: #0000FF08; }
.cell.roadmap-cell.roadmap-cell-item-8 a { background-color: #0000FF08; }
.cell.roadmap-cell.roadmap-cell-item-9 a { background-color: #0000FF08; }

.roadmap-project-header-row.project-index-header-0 a { color: #AAAAFF88; }
.roadmap-project-header-row.project-index-header-1 a { color: #AAFFAA88; }
.roadmap-project-header-row.project-index-header-2 a { color: #FFFFAA88; }
.roadmap-project-header-row.project-index-header-3 a { color: #FFAAAA88; }
.roadmap-project-header-row.project-index-header-4 a { color: #FFAAFF88; }
.roadmap-project-header-row.project-index-header-5 a { color: #AAAAFF88; }
.roadmap-project-header-row.project-index-header-6 a { color: #AAAAFF88; }
.roadmap-project-header-row.project-index-header-7 a { color: #AAAAFF88; }
.roadmap-project-header-row.project-index-header-8 a { color: #AAAAFF88; }
.roadmap-project-header-row.project-index-header-9 a { color: #AAAAFF88; }


.weeks-header {
    font-size: 12px;
}

.weeks-header > .cell {
}

.days-header {
    font-size: 12px;
    margin-bottom: 8px;
}

.days-header > .cell {
    width: 100px;
    font-size: 12px;
}

.roadmap-document-viewer { 
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
    border-top: var(--light-border-color) 1px solid;
    border-left: var(--light-border-color) 1px solid;
    overflow: auto;
    color: unset;
}

/**
Border
 */
.roadmap-document-viewer .roadmap-document-column {
    border-right: var(--light-border-color) 1px solid;
    /* border-right: var(--light-border-color) 1px solid; */
}

.roadmap-document-viewer > .roadmap-document-column .roadmap-week-cell,
.roadmap-document-viewer > .roadmap-document-column .roadmap-day-cell,
.roadmap-document-viewer > .roadmap-document-column .project-item-cell { 
    padding: 0;
    margin: 0;
    min-width: 100px;
}

.roadmap-document-viewer > .roadmap-document-column .project-item-cell { 
    background-color: #FF000044;
}

/**
Header cell of each column
 */
.roadmap-document-viewer > .roadmap-document-column > .roadmap-document-column-header {
    display: block;
    color: var(--bocg-blue);
    background-color: var(--dark-background-color);
}

.roadmap-document-viewer > .roadmap-document-column > .roadmap-document-column-header a {
    color: var(--bocg-blue);
}

.roadmap-document-viewer > .roadmap-document-column > .roadmap-document-column-header a:hover {
    color: var(--bright-text-color);
}

/**
Inner Header cell of each column. Provides padding
 */
.roadmap-document-viewer > .roadmap-document-column .roadmap-document-column-header > span { 
    display: inline-block;
    padding: 8px;
}

/**
Cell that has a fixed height (set in the style within the html element).
 */
.roadmap-document-viewer > .roadmap-document-column .cell { 
    white-space: nowrap;
    display: flex;
    width: 100%;
    min-width: 100%;
    align-items: center;
    justify-content: center;
}

/**
Cell that represents if it falls on today.
 */
.roadmap-document-viewer > .roadmap-document-column.project .roadmap-document-column-item.cell.project-item.today,
.roadmap-document-viewer > .roadmap-document-column .cell.today {
    background-color: #15000050;
}

/**
Inner Cell that has a border. The outer div is what sets the size of the cell.
 */
.roadmap-document-viewer > .roadmap-document-column .cell > span {
    padding: 8px;
    border-bottom: var(--light-border-color) 1px solid;
    /* border-bottom: #CCC 1px solid; */
    width: 100%;
    display: block;
    position: relative;
}

.roadmap-document-viewer > .roadmap-document-column.days,
.roadmap-document-viewer > .roadmap-document-column.weeks {
    text-align: center;
    vertical-align: middle;
    align-items: center;
    align-content: center;
}

.roadmap-document-viewer > .roadmap-document-column.project {
    flex-grow: 1;
    display: block;
}

.roadmap-document-viewer > .roadmap-document-column.days > .roadmap-document-column-header,
.roadmap-document-viewer > .roadmap-document-column.weeks > .roadmap-document-column-header { 
    max-width: 148px;
    width: 148px;
    display: block;
    flex: 4;
}

.roadmap-document-viewer > .roadmap-document-column.days > .roadmap-document-column-header {
    max-width: 80px;
    width: 80px;
}

.roadmap-document-viewer > .roadmap-document-column.weeks .roadmap-document-column-week.cell {
    /* background-color: #FF000022;  */
}

.roadmap-document-viewer > .roadmap-document-column.weeks .roadmap-document-column-week.cell label { 
    display: block;
    font-size: 14px;
    white-space: pre-wrap;
    line-height: 1.6em;
}

.roadmap-document-viewer > .roadmap-document-column.weeks .roadmap-document-column-week.cell > span { 
    height: 100%;
    min-height: 100%;
}

.roadmap-document-viewer > .roadmap-document-column.weeks .roadmap-document-column-week.cell > span.big-week {
    font-size: 80px;
    padding-top: 24px;
}

.roadmap-document-viewer > .roadmap-document-column.days .roadmap-document-column-day.cell {
    background-color: #00000050;
    font-size: 20px;
}

.roadmap-document-viewer > .roadmap-document-column.days .roadmap-document-column-day.cell.today {
    background-color: #15000050;
    color: var(--yellow);
}

h3.today { 
    font-size: 18px!important;
    margin-bottom: 0 !important;
    padding: 12px 24px !important
}

.roadmap-document-viewer > .roadmap-document-column.days .roadmap-document-column-day.cell span {
    height: 100%;
    padding-top: 12px;
}

.roadmap-document-viewer > .roadmap-document-column.project .roadmap-document-column-header { 
    width: 100%;
    min-width: 100%;
}
.roadmap-document-viewer > .roadmap-document-column.project .roadmap-document-column-item {
    position: relative;
    overflow: clip;
}

.roadmap-document-viewer > .roadmap-document-column.project .roadmap-document-column-item.cell.project-item {
    width: 100%;
    min-width: 100%;
    display: flow;
    background-color: #00000020;
}

.roadmap-document-viewer > .roadmap-document-column.project .roadmap-document-column-item.cell > span {
    font-size: 12px;
    height: 100%;
    max-width: 600px;
}

.roadmap-document-viewer > .roadmap-document-column.project .roadmap-document-column-item.cell > span.wrap {
    display: flex;
    white-space: nowrap;
    align-items: center;
}

.roadmap-document-viewer > .roadmap-document-column.project .roadmap-document-column-item.cell > span > label { 
    display: inline-block;
    cursor: pointer;
    padding: 5px 10px;
    background-color: #333;
    border-radius: 4px;
    margin-right: 6px;
    margin-bottom: 4px;

    box-shadow: 1px 1px 5px #00000046;
}

.roadmap-document-viewer > .roadmap-document-column.project .roadmap-document-column-item.cell > span > label.selected {
    color: yellow;
}

.roadmap-document-viewer > .roadmap-document-column.project .roadmap-document-column-item.cell > span > label:hover { 
    
}

.roadmap-document-viewer > .roadmap-document-column.project .roadmap-document-column-item.cell > span.wrap > label {
    white-space: pre-line;
    white-space-treatment: preserve;
    font-size: 0.65vw;
}

.roadmap-document-viewer > .roadmap-document-column.project .roadmap-document-column-item.cell > span > label > span {
}

.roadmap-document-viewer > .roadmap-document-column.project .roadmap-document-column-item.cell > span.multi { 
    
}

.roadmap-document-viewer > .roadmap-document-column.project .roadmap-document-column-item.cell > span.multi:hover { 
    z-index: 50;
}

.roadmap-document-viewer > .roadmap-document-column.project .roadmap-document-column-item.cell > span.empty {
    
}

.roadmap-document-viewer > .roadmap-document-column .days {
    text-align: center;
}


.project-item div.user-badge {
    gap: 16px;
    background-color: var(--dark-background-color);
}

.project-item div.user-badge span.user-badge-image {

}

.project-item .user-badge span.user-badge-image > a > img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.project-item .user-badge.roadmap ul > li:first-child {
    font-size: 14px !important;
    font-weight: bold !important;
}

div.user-badge.roadmap span.user-badge-image > a > .user-badge-initials {
    width: 48px;
    height: 48px;
    background-color: var(--bocg-blue-faded);
}


div.user-badge .project-item-card { 
    display: block;
    margin-bottom: 12px;
}

div.user-badge.roadmap span.user-badge-image > a > .user-badge-initials > label {
    color: white;
    padding-top: 16px;
    font-size: 20px;
    display: block;
}


.project-item-card.container {
    line-height: 1.6em !important;
    display: flex !important;
    align-items: center;
}


.project-item-card.container .project-item-card-options { 
    padding-right: 8px;
    padding-top: 12px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 12px;
}

.project-item-card.container .project-item-card-options a {
    display: inline-block;
    text-align: center;
    width: 32px;
    min-width: 32px;
    background-color: #000000;
    font-size: 20px;
    padding: 6px 0;
    
    border-radius: 5px;
    border: var(--light-border-color) 1px solid;
}

.project-item-card.container .project-item-card-options a svg {
    display: inline-block;
    height:20px;
}

.project-item-card.container .project-item-card-options a:hover svg {
    color: var(--bocg-blue);
}


.project-item-card p a { 
    color: var(--yellow);
    text-decoration: underline !important;
}

.project-item-card h2,
.project-item-card h3,
.project-item-card h4 { 
    font-weight: bold !important;
    color: white !important;
    margin-bottom: 8px !important;
    margin-top: 1px !important;
}

div.user-badge.roadmap h2:first-child,
.project-item-card h2:first-child { 
    margin-top: 0 !important;
}

div.user-badge.roadmap div.project-item-project-title { 
    display: block;
    padding-bottom: 0;
    margin-bottom: 0;
    color: var(--bocg-blue);
}

div.user-badge.roadmap div.project-item-project-title + .project-item-card h2 { 
    margin-top: 0;
}

div.user-badge.roadmap ul {
    display: flex;
    flex-direction: column-reverse;
}

/* Cell day <=> date swithcer classes */
.roadmap-document-column.days .roadmap-document-column-day.cell span.day-cell-date { 
    display: none;
    font-size: 13px;
}

.roadmap-document-column.days .roadmap-document-column-day.cell span.day-cell-date label { 
    font-size: 18px;
    color: var(--bright-text-color);
    font-weight: bold;
    margin-bottom: 4px;
    display: block;
}

.roadmap-document-column.days.date .roadmap-document-column-day.cell span.day-cell-date {
    display: block;
}

.roadmap-document-column.days .roadmap-document-column-day.cell span.day-cell-day {
    font-size: 32px;
    text-shadow: 1px 1px 4px #000000;
    transition-property: text-shadow;
    transition-duration: 300ms;
}

.roadmap-document-column.days.date .roadmap-document-column-day.cell span.day-cell-day {
    display: none;
    text-shadow: none;
}


