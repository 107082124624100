.api-form {
    
}

.api-form .form-group {
    font-size: 13px;
}

.api-form .form-group > span > input[type="text"] {
    width: 400px;
}

.api-form > label {

}

.api-form > span {
    display: inline-block;
    margin-left: 8px;
}

.api-form > span > input[type="text"] { 
    width: 300px;
}

.api-form > span > button {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    margin-left: 12px;
}
