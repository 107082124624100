div.company-docs-drop-target { 
    
}

.details-body .right.container.stage-dropdown {
    margin-right: 24px;
}

.details-body h2.options {
}

h2.options .right.container {
    float: right;
    margin-right: 24px;
}

li.sub-menu-company-status { 
    display: block;
    padding: 0;
    margin: 0;
}

div.company-status { 
    margin: 16px 12px;
    border-radius: 8px;
    border: var(--light-border-color) 1px solid;
    overflow: clip;
}

div.company-status > div > a { 
    padding: 8px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: var(--text-color-faded);
    opacity: 0.4;
}

.no-copy-padding > .copy-text svg:last-child {
    margin-left: 0px;
}

.copy-line-item .copy-text:hover svg { 
    color: yellow;
}

div.company-status > div > a > span:last-child > svg { 
    opacity: 0.0;
}

div.company-status > div.selected > a > span:last-child > svg {
    opacity: unset;
}

div.company-status > div.selected > a:hover > span:last-child > svg {
    opacity: 0.8;
}

div.company-status > div:first-child { 
    border-bottom: var(--light-border-color) 1px solid;
}

div.company-status > div > a:hover,
div.company-status > div.selected > a {
    background-color: var(--dark-background-color);
    color: var(--bocg-blue);
    opacity: 1.0;
}

div.company-status > div.selected > a > span > svg { 
    color: var(--good-green);
}

div.company-status > div > a:hover { 
    background-color: black;
}

.company-image-upload {
    margin-right: 24px;
    border-radius: 12px;
}

.company-image-upload:empty { 
    margin-right: 0;
}

.company-image-upload .upload-drop { 
    display: block;
}

.company-image-upload .upload-drop .file-upload-form-elements { 
    display: block;
}

.company-image-upload .upload-drop .file-upload-form-elements > span { 
    display: block;
    text-align: center;
}

.company-image-upload .upload-drop .file-upload-form-elements > span .file-upload-element { 
    display: none;
}

button.file-upload-button {
    
}

.company-image-upload .upload-drop .file-information .file-selector { 
    text-align: center;
}

.company-image-upload .upload-drop .file-upload-container { 
    text-align: center;
    margin: 0 auto;
}

.company-image-upload .upload-drop .file-upload-container .file-upload-form-elements {
    display: none;
}

.company-image-upload .upload-drop .file-upload-container.selected .file-upload-form-elements {
    display: inline-block;
}

.company-image-upload .upload-drop .file-information .file-selector .file-selector-image { 
    text-align: center;
    margin: 16px auto;
    border: var(--light-border-color) 4px solid;
    border-radius: 50%;
    overflow: clip;
    width: 128px;
    height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.company-image-upload .upload-drop .file-information .file-selector .file-selector-image svg { 
    width: 64px;
    height: 64px;
}

.company-image-upload .upload-drop .file-information .file-selector .file-selector-image img {
    width: 128px;
    height: 128px;
}

.company-image-upload .upload-drop .file-information .file-selector ul.file-selector-detail-list {
    font-size: 12px;
    width: 200px;
    margin: 0 auto;
    text-align: left;
    display: none;
}

.company-image-upload .upload-drop .file-upload-form-elements > span .file-upload-cancel {
    font-size: 14px;
    display: block;
    padding-top: 6px;
}
