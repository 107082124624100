
.unpaid, .unpaid a { 
    color: var(--overdue-red);
}

.not-due { 
    display: none;
}

.due-date { 
    
}

tr.smaller > td.sent,
.due-date > span {
    color: var(--yellow);
}

tr.smaller > td,
td.small-date { 
    font-size: 0.85rem;
    color: var(--text-color);
}

tr.smaller > td.normal { 
    font-size: 1rem;
}

td.overdue,
.overdue > span {
    color: var(--overdue-red);
}

tr.smaller > td.paid {
    color: var(--connected-green);
    opacity: 0.5;
}

tr.overdue td.invoice-status { 
    color: var(--overdue-red);
}

.invoice-attachments-panel { 
    border-radius: 8px;
    background-color: var(--panel-color);
    padding: 24px;
    margin-bottom: 32px;
    position: relative;
}

.invoice-attachments-panel h3 { 
    margin-bottom: 12px;
}

.invoice-attachments-panel > .files { 
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: stretch;
    gap: 12px;
}

.invoice-attachments-panel>.files > a { 
    padding: 6px 12px;
    background-color: black;
    border-radius: 6px;

}

.invoice-attachments-panel>.files.edit > a { 
}

.new-file > svg,
.file > svg { 
    margin-right: 0;
    width: 0px;
    overflow: hidden;
    display: inline-block;
    transition-property: width, margin-right;
    transition-timing-function: linear;
    transition-duration: 200ms;
    transition-delay: 10ms;
    color: var(--text-error-color);
    overflow: clip;
}

.edit .new-file>svg,
.edit .file>svg {
    width: 10px;
    margin-right: 10px;
}

.file>svg > path,
.new-file>svg > path { 
    display: none;
}

.new-file {
    animation-name: new-file-animation;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes new-file-animation {
    0% { 
        opacity: 1.0;
    }
    50% { 
        opacity: 0.35;
    }
    100% {
        opacity: 1.0;
    }
}

.upload-invoice-documents-button { 
    margin: 16px 0 0 0;
    display: flex;
    justify-content: flex-start;
    gap: 48px;
}

.filter-panel { 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
    gap: 16px;
    font-size: 13px;
}

.filter-panel > span > a { 
    display: inline-block;
    padding: 6px 10px;
    border-radius: 8px;
    border: var(--border-color) 1px solid;
}

.filter-panel > .selected > a {
    border-color: var(--bocg-blue-faded);
    color: var(--bocg-blue);
}

