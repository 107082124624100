div.drop-target { 
    border: 1px dashed #ccc;
    border-radius: 12px;
    padding: 42px 12px;
    margin-bottom: 32px;
    text-align: center;
}

.big div.drop-target { 
    padding: 48px 24px;
}

div.drop-target > label { 
    display: block;
    padding-bottom: 12px;
}

div.drop-target.over,
div.drop-target:hover { 
    background-color: black;
}

.content-dropper { 
    clear: both;
    background-color: var(--dark-background-color);
    border-radius: 8px;
    padding: 24px;
    border: var(--light-border-color) 1px dashed;
}

.content-dropper > label { 
    display: block;
    margin-bottom: 16px;
}

.content-dropper.x-large {
    min-height: 400px;
}
