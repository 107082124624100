.details-body .form.medium textarea.markdown-textarea.edit-project { 
    min-height: 160px !important;
}

#project-item-main .details-body .comments { 
    margin-top: 64px;
}

div.view-content { 
    width: 100%;
    display: block;
    text-align: center;
    align-items: center;
    align-content: center;
    align-self: center;
    background-color: black;
    border-radius: 16px;
}

div.content-comments .menu-header {
    margin-top: 24px !important;
}

table td > a > span.day { 
    display: inline-block;
    width: 70px;
    text-align: center;
    padding: 6px 8px;
    border-radius: 5px;
    font-size: 13px;
    background-color: var(--dark-background-color);
    margin-right: 12px;
}

.nav-history.projects-due { 
    overflow: clip;
    text-overflow: ellipsis;
}

div.project-items-due-body { 
    display: block;
    overflow-y: auto;
    max-height: 600px;
    position: relative;
}

div.project-items-due-body ul { }
div.project-items-due-body ul > li {
    margin-bottom: 16px;
    padding: 8px;
    border-radius: 16px;
    background-color: var(--projects-due-background-color);
}

div.project-items-due ul > li > div {
    display: flex;
    justify-content: flex-start;
}

div.project-items-due-body ul > li > div > span { 
    
}

div.project-items-due-body ul > li > div > span.image { 
    margin-right: 16px;
}

div.project-items-due-body ul > li > div > span.image svg {
    width: 32px;
    height: 32px;
}

div.project-items-due-body ul > li > div > span.image img {
    width: 32px;
    height: 32px;
    max-height: 32px;
    max-width: 32px;
    border-radius: 50%;
}

div.project-items-due-body ul > li > div > span > h3 { 
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

div.project-items-due-body ul > li > div > span > p { 
    display: none;
    margin-bottom: 0;
    margin-right: 0;
    max-height: 48px;
    text-overflow: ellipsis;
    overflow: clip;
}

div.project-items-due-body ul > li > div > span:last-child > span { 
    display: block;
    margin-bottom: 8px;
}

div.project-items-due-body ul > li.past-due > div > span:last-child > span.date { 
    color: var(--error-red);
}

div.project-items-due-body > ul > li .project-company { 
    font-size: 14px;
    font-weight:bold;
    opacity: 0.8;
}

div.project-items-due-body>ul>li .project-company > strong { 
    /* */
}

.sub-column + .pad.x-x { 
    flex: unset;
    width: max-content;
    display: block;
}


.scroll-container div.workflow-body { 
    display: block;
    width: min-content;
    max-width: min-content;
    overflow-x: scroll;
}

div.workflow-view {
    display: flex;
    gap: 16px;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

/* Important. If this isn't 100%, the ability to drop cards in an empty column goes away */
div.workflow-column-items {
    height: 100%;
}

div.workflow-column { 
    min-width: 220px;
}

div.workflow-column div.project-item-card {
    border: var(--light-border-color) 1px solid;
    margin-bottom: 12px;
    display: block;
    font-size: 13px;
    overflow: clip;
    border-radius: 8px;
}

div.workflow-column div.project-item-card div { 
    display: block;
}

div.workflow-column div.project-item-card div.title { 
    padding: 8px;
    background-color: var(--dark-background-color);
    color: var(--bright-text-color);
}

div.workflow-column div.project-item-card div.footer {
    font-size: 12px;
    padding: 8px;
}

div.card-skeleton { 
    display: block;
    width: 100%;
    margin-bottom: 12px;
    border: var(--light-border-color) 1px dashed;
    background-color: var(--dark-background-color);
    border-radius: 8px;
}

div.card-skeleton > span { 
    display: block;
    font-size: 14px;
    padding: 22px 12px;
    text-align: center;
}