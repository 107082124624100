
span.glossary-letter { 
    display: inline-block;
    margin-right: 6px;
}

span.glossary-letter a {
    padding: 4px 6px;
    border-radius: 4px;
    display: block;
    text-transform: uppercase;
    background-color: var(--dark-background-color);
}

span.glossary-letter a.empty { 
    opacity: 0.5;
}

span.glossary-letter a:hover { 
    color: var(--bocg-blue);
    background-color: var(--darkest);
}

ul.glossary { 
    
}

ul.glossary > li {
    border-bottom: var(--light-border-color) 1px dashed;
    padding-bottom: 12px;
    margin-bottom: 24px;
}

ul.glossary > li:last-child {
    border-bottom-width: 0;
}

ul.glossary > li > div > h3 {
    font-size: 22px;
    color: var(--bocg-blue);
    margin-bottom: 8px;
}

ul.glossary > li > div > p.glossary-definition { 
    margin: 0;
}

ul.glossary > li > div > p.glossary-definition p {
    margin: 12px 0;
}


