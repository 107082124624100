
table.contact-requests-table { 
    
}

table.contact-requests-table td svg { 
    margin-right: 8px;
}

table.contact-requests-table td.preview { 
    font-size: 12px;
}

table.contact-requests-table tr.unread td,
table.contact-requests-table tr.unread td > a { 
    color: var(--yellow);
}

table.contact-requests-table td svg,
table.contact-requests-table tr.unread td > a > svg { 
    color: var(--text-color);
}

span.contact-request-profile > a > svg { 
    color: var(--yellow);
    margin-right: 5px;
}

div.contact-body { 
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    
    padding: 24px;
    background-color: var(--transparent-background-color);
    border: var(--light-border-color) solid 1px;
    line-height: 150%;
    border-radius: 12px;
}

div.contact-body .contact-body-message {
    white-space: pre-line;
    flex: 2;
    font-size: 18px;
    max-height: 400px;
    overflow-y: auto;
    margin-right: 12px;
}

.contact-info-header { 
    padding: 12px;
    background-color: var(--transparent-background-color);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contact-body > .contacts { 
    min-height: 120px;
}

.contact-body > .contacts > .contact { 
    padding: 8px;
    border-bottom: var(--border-color) solid 1px;
}
.contact-body > .contacts > .contact:first-child { 
    padding-top: 0;
}

.contact-body > .contacts > .contact:last-child { 
    border-bottom-width: 0;
}