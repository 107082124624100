div.comment { 
    border-bottom: rgba(255, 255, 255, 0.2) 1px solid;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    padding-bottom: 16px;
}

.comment > span:first-child { 
    display: inline-block;
    width: 64px;
    height: 64px;
    margin-right: 12px;
}
.comment > span:last-child { 
    width: fill-available;
}

.comment > span:first-child img { 
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.comment > span > p { 
    margin: 12px 0 12px 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 12px 16px;
}

.comment > span > p.comment-text > p {
    margin: 0;
    min-height: 48px;
    color: #FFFFCCDD;
}

.comment > span > div.comment-date { 
    font-size: 13px;
    display: flex;
    justify-content: space-between;
}

.comment-form { 
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    align-items: start;
}

.comment-form textarea { 
    border-radius: 8px;
    width: fill-available;
    height: 80px;
    margin-right: 16px;
}

.comments > label { 
    display: block;
    padding-bottom: 12px;
}

.comment > span > div.comment-date > span.comment-action-panel {
    display: inline-block;
    padding-right: 12px;
}

.comment > span > div.comment-date > span.comment-action-panel a {

}

.comment > span > div.comment-date > span.comment-action-panel a:hover { 
    
}

.comment > span > p.comment-text { 
}

