
div.user-badge {
    background-color: var(--dark-background-color);
    border-radius: 24px;
    padding: 16px 24px 16px 16px;
    width: fit-content;
}

div.user-badge div.user-badge-user {
    display: flex;
    align-items: center;
    gap: 16px;
}

div.user-badge span.user-badge-image {
    
}

div.user-badge span.user-badge-image > a > .user-badge-initials {
    background-color: black;
    display: inline-block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
}

div.user-badge span.user-badge-image > a > .user-badge-initials > label {
    display: block;
    text-align: center;
    font-size: 24px;
    height: 64px;
    padding-top: 18px;
    min-height: 64px;
    color: var(--bocg-blue);
}

.user-badge span.user-badge-image > a {
}

.user-badge span.user-badge-image > a > .img,
.user-badge span.user-badge-image > a > img {
    size: 64px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-repeat: no-repeat;
    display: inline-block;
    object-fit: cover;
}

.user-badge span.user-badge-name {

}

.user-badge span.user-badge-name ul li {
    font-size: 14px;
}

.user-badge span.user-badge-name ul li:first-child {
    font-weight: bold;
    margin-bottom: 8px;
    font-size: inherit;
}

.MuiTooltip-arrow.user-badge-tooltip-arrow {
    color: #000000CC !important;
    border: red 1px solid !important;
}

.MuiTooltip-tooltip.user-badge-tooltip {
    background-color: #000000CC !important;
    border-radius: 24px !important;
    border: red 1px purple !important;
}

.MuiTooltip-tooltip .user-badge {
    background-color: #00000000 !important;
}
