.markdown-preview,
.details-body .form.medium .markdown-preview {
    display: inline-block;
    border: var(--light-border-color) 1px solid;
    width: 100%;
    background-color: var(--dark-background-color);
    min-height: 90px;
}

.markdown ul {
    list-style: disc;
    padding: unset;
    margin-bottom: 24px;
}

.markdown ul li {
    margin-left: 32px;
}

.markdown ul::before,
.markdown ul li::before,
.markdown ul::after,
.markdown ul li::after {
    padding-left: 12px;
}

.markdown ul li {
    list-style: unset;
}

.markdown-preview ul li {
}

.markdown-preview.markdown {
    padding-bottom: 24px;
}

.markdown,
.details-body .markdown {
    background-color: var(--dark-background-color);
    border-radius: 12px;
    padding: 24px 24px 48px 24px;
    max-width: unset;
    margin-right: unset;
}

.markdown a,
.details-body p.markdown a {
    color: var(--bright-text-color);
}

.markdown a:hover,
.details-body p.markdown a:hover {
    color: var(--bocg-blue);
}

.details-body p.markdown {
    margin-right: 0;
    position: relative;
}

.details-body p.markdown .right.container {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 14;
}

.details-body p.markdown.has-details { 
    margin-bottom: 0;
}

.details-body .markdown span > p {
    margin-bottom: 12px;
}

.details-body .markdown span > p:last-child {
    margin: 0;
}

.details-body p.markdown span > p p {
    margin: unset;
}

.dialog span.markdown {
    width: 100%;
}

.details-body p.markdown * {
    color: var(--markdown-text-color);
}

.markdown-tooltip {
}

.markdown-tooltip-body { 
    display: inline-block;
    font-size: 12px;
    line-height: 1.5em;
    padding: 8px;
}

.markdown-tooltip-body a { 
    display: block;
    padding: 6px;
    margin-top: 5px;
    border-radius: 4px;
    background-color: #FFFFFF11;
}

.markdown-tooltip-body a:hover {
    text-decoration: underline;
    background-color: #FFFFFF22;
}

.markdown-tooltip-body a svg { 
    margin-left: 8px;
}

.markdown-tooltip-body h1 { 
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
}

.dark-body { 
    background-color: #000000CC !important;
    border-radius: 8px !important;
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.details-body .markdown h1,
.details-body .markdown h2,
.details-body .markdown h3,
.details-body .markdown h4,
.details-body .markdown h5 {
    color: var(--bright-text-color);
    margin-bottom: 8px;
}

