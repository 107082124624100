a.activity-link { 
    position: relative;
    display: inline-block;
}

.activity-link-content {
    display: flex;
    position: relative;
}

.activity-link > label {
    animation: spin 0.5s linear infinite;
    display: block;
    opacity: 0.0;
    position: absolute;
    z-index: 12;
    top: 0;
    right: 0;
    width: 16px;
    height: 16px;
    font-size: 3px;
    color: transparent;
    line-height: 0;
    border: red 2px solid;
    border-top-color: white;
    border-radius: 50%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a.activity-link.activity > label {
    opacity: 1.0;
}

a.activity-link > span.activity-link-content { 
    padding-right: 24px;
}

a.activity-link.activity > span.activity-link-content { 

}


