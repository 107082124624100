
.search-box > input::placeholder {
    color: var(--text-color-faded);
    font-weight: lighter;
}

.search-box > input[type="text"] {
    width: 100%;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: lighter;
}

#master-search-container {
    height: 80px;
    position: relative;
}

.search-results {
    position: absolute;
    top: 46px;
    left: 0;
    right: 0;
    border: var(--border-color) 1px solid;
    background-color: black;
    width: 100%;
    border-radius: 12px;
    min-height: 70px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 32;
}

.search-results > ul { 
    display: block;
    padding-top: 24px;
    padding-right: 24px;
    padding-left: 24px;
}

.search-results > ul:last-child { 
    padding-bottom: 24px;
}

.search-results > ul > li { 
    display: block;
    margin-bottom: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: var(--light-border-color) 1px dashed;
}

.search-results > ul > li.search-result-title { 
    font-weight: bold;
    padding-top: 0;
    color: var(--bocg-blue);
}

.search-results > ul > li > a { 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.search-results > ul > li > a:hover > * {
}

.search-results > ul > li > a > span > h4 {
    margin: 0;
    padding-bottom: 4px;
}

.search-results > ul > li > a > span:first-child { 
    width: 42px;
}

.search-results > ul > li > a > span:first-child svg { 
    min-height: 24px;
}

.search-results > ul > li > a > span:first-child img { 
    object-fit: cover;
    border-radius: 50%;
    border: rgba(100, 100, 100, 0.6) 1px solid;
    width: 32px;
    height: 32px;
}

.search-results > ul > li > a > span > p {
    margin: 0;
    font-size: 12px;
}

.search-results > ul > li span.preview { 
    display: block;
    font-size: 12px;
    color: var(--yellow);
    font-weight: bold;
}

.search-results > ul > li > a:hover h4,
.search-results > ul > li > a:hover > span:first-child svg,
.search-results > ul > li > a:hover p,
.search-results > ul > li > a:hover span {
    color: var(--bocg-blue);
}

.search-results > ul > li:last-child { 
    margin-bottom: 0;
}

.search-results ul > li > strong { 
    text-align: center;
    display: block;
    font-weight: normal;
}

#master-search-container .search-results {

}

#master-search-input {
    font-size: 20px;
    font-weight: lighter;
}

#master-search-input::placeholder {
    color: var(--text-color-faded);
    font-weight: lighter;
}
