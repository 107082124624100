@charset "utf-8";

:root {
    --background-color: #000;
    --text-color: #FFFFFFAA;
    --input-text-color: #FFFFFF;
    --input-background-color: #111;
    --bright-text-color: white;
    --header-color: #FFFFFFDF;
    --text-color-faded: #FFFFFF55;
    --text-error-color: pink;
    --text-warning-color: #FF5;
    --link-color: #FFFFFFCC;
    --markdown-text-color: #EEFFFF88;
    --dark-background-color: rgba(0, 0, 0, 0.25);
    --lighter-background-color: rgba(80, 80, 80, 0.25);
    --transparent-background-color: #111;
    --panel-color: rgba(0, 80, 80, 0.2);
    --darkest: black;

    --badge-color: #FF000022;
    --badge-text-color: #FFFFFFAA;
    --badge-hover-color: white;
    
    --button-color: #333;
    --button-confirm-color: #2fA142;
    --button-hover-color: #1f8142;
    --button-action-hover-color: #001016;
    --failed-line-item-color: rgba(255, 150, 150, 0.7);
    --error-red: rgba(255, 150, 150, 0.7);
    --error-red-background: rgba(255, 0, 0, 0.25);
    --error-red-background-hover: rgba(255, 0, 0, 0.5);
    --warning-red: rgb(255, 150, 150);
    --delete-button-color: rgba(255, 0, 0, 0.1);
    --delete-button-hover-color: rgba(255, 0, 0, 0.3);
    --delete-button-color-light: rgba(255, 140, 140, 0.75);
    --disabled-text-color: rgba(255, 255, 255, 0.35);
    --bg-color: #081329;
    --delete-link-color: #AA0000;
    --connected-green:#99FF99;
    --connected-green-faded:#99FF99CC;

    --main-column-background-color: #001016;
    --pod-color: #001016;
    --column-background-color: rgba(70, 70, 70, 0.15);
    --projects-due-background-color: #FFFFFF0A;

    --confirm-background-color: #111;
    --dark-text-color: #333;
    
    --bocg-blue: #00FFFFFF;
    --bocg-blue-faded: #00FFFF44;
    --bocg-blue-light: #00FFFFFF;
    --bocg-blue-bg: #77AAFF11;
    
    --good-green: #00FF00AA;
    --success-light-green: #EEFFCCAA;
    --yellow: #CCCC00;
    --yellow-faded: #FFFF8877;
    
    --border-color: #DDDDDD30;
    --light-border-color: #FFFFFF21;

    --line-hover-background-color: #FFFFFF11;

    --code-font: "Lucida Console", Monaco, monospace;
    --code-color: #00FFFFAA;
    --yellow-bright: #CC0;
    --error-code-color: pink;
    --overdue-red: rgb(255, 127, 149);
    --delete-button-border-red: #FF7777AA;
    --delete-button-red: #FF7777;
    --pi-modal-dialog-title-border: #CCC 1px dashed;
    --transition-type-spring: cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

*,
*::before,
*::after {
    font-family: 'Archivo Narrow', Arial, Helvetica, sans-serif;
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
    outline: none;
}

html {
    height: 100%;
    display: block;
}

::-webkit-input-placeholder {
    color: inherit;
    opacity: 1;
}

:-ms-input-placeholder {
    color: inherit;
    opacity: 1;
}

::placeholder {
    color: inherit;
    opacity: 1;
}

body {
    font-size: 16px;
    color: var(--text-color);
    background-color: var(--background-color);
    height: 100%;
    display: block;
}

body::-webkit-scrollbar {
    width: 2px;
    background-color: black;
}

td::-webkit-scrollbar { 
    width: 0;
    background-color: #00000000;
}

a {
    color: var(--link-color);
    cursor: pointer;
}

a.faded { 
    color: var(--text-color-faded);
}

a.faded:hover { 
    color: var(--yellow);
}

a.icon svg { 
    margin-right: 8px;
}

a:hover {
    color: white;
}

.notes { 
    font-size: 0.85em;
}

.details-body p.notes,
p.notes { 
    margin-top: 32px;
    margin-right: unset;
    max-width: unset;
}

p.notes > strong { 
    color: white;
}

.normal {
    font-size: 16px;
}

a.button {
    font-size: 14px;
    padding: 6px 10px;
    background-color: #BBBBBB22;
    border-radius: 6px;
}

.normal > a {
    margin: 5px;
    display: inline-block;
}

.footer-action-panel {
    padding: 16px 0;
}

.code { 
    background-color: black;
    padding: 12px;
    font-family: 'Courier New', Courier, monospace;
    white-space: pre-wrap;
    word-wrap:normal;
    font-size: 1.2em;
    border-radius: 12px;
    overflow: auto;
}

.MuiDialog-paperScrollPaper .code.override {
    min-width: 800px !important;
}

.company-login-image { 
    display: flex;
    padding-bottom: 24px;
    border-radius: 50%;
    overflow: clip;
    justify-content: center;
    align-items: center;
}

.company-login-image img { 
    width: 100px;
    height: 100px;
}

.dragger { 
    cursor: move;
}

.dropper { 
    
}

.dropper.over {
    /* background-color: var(--dark-background-color); */
}

#dashboard-main h1 { 
    
}

.users-line-graph { 
}

.users-line-graph .line-graph-body {
    height: 100%;
    display: inline-block;
    border-radius: 8px;
    min-height: 220px;
    min-width: 100%;
}

h1, h2, h3, h4 {
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 24px;
    position: relative;
    color: var(--header-color);
}

h1 img.company-h1-image { 
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 16px;
    vertical-align: -0.125em;
}

h2 > span.header-button {
    display: inline-block;
    font-size: 16px;
    align-content: end;
    align-items: end;
    horiz-align: right;
    text-align: right;
    flex: fit-content;
    white-space: nowrap;
}

h2 > span.header-button > a {
    display: none;
    padding: 10px 12px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.25);
    float: right;
    font-size: 16px;
}

h2:hover > span.header-button > a,
h2 > span.header-button.persist a {
    display: inline-block;
}

h2 span.header-button > a > svg { 
    margin-right: 6px;
}

h2 img.icon {
    max-width: 32px;
    max-height: 32px;
    display: inline-block;
}

h3 img.icon {
    max-width: 24px;
    max-height: 24px;
    display: inline-block;
}

h3.view-state-1 svg,
h3.view-state-0 svg {
    margin-left: 12px;
}

h3.view-state-1 svg { 
    color: var(--bocg-blue);
}

h1.menu-header,
h2.menu-header,
h3.menu-header,
h4.menu-header {
    display: flex;
    gap: 12px;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

.dialog-document-editor > .document-form {
    width: 400px;
}

.dialog-document-editor > .document-form textarea,
.dialog-document-editor > .document-form input[type="text"] {
    width: 100%;
}

.dialog-document-editor > .document-form textarea {
    height: 128px;
}

.editable-content {
    display: none;
}

.editable-content.block {
    display: block;
}

.editable-content.inline-block {
    display: inline-block;
}

.dashboard-columns {
    display: flex;
    flex-wrap: nowrap;
    gap: 32px;
    justify-content: space-between;
}

.dashboard-columns.even > div { 
    flex: 1 !important;
    max-width: 50%;
}

.dashboard-columns > *:last-child { 
    flex: 4;
}

.dashboard-row { 
    /* */
}

.content-thumb {
    width: 100px;
    height: 100px;
    line-height: 0;
    object-fit: cover;
    border-radius: 6px;
}

.trimmed {
    padding: 0 0 16px 0 !important;
    margin-bottom: 32px !important;
    border-radius: 0!important;
    text-overflow: ellipsis;
    max-height: 128px;
    overflow: clip;
    display: block;
    
    transition-delay: 1s;
    transition-timing-function: linear;
    transition-duration: 400ms;
    transition-property: max-height;
}

.trimmed:hover {
    max-height: 100vh;
}

.api-keys-table {
    
}

.api-keys-table .api-key-row {
    border-radius: 12px;
    background-color: var(--main-column-background-color);
    padding: 12px 16px 8px 16px;
}

.api-keys-table .api-key-row > div:first-child {
    padding-bottom: 8px;
    display: block;
    margin-bottom: 8px;
}

.api-keys-table .api-key-row > div:first-child > *:first-child {
    font-size: 1.1em;
}

.date-footer {
    gap: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    font-size: 0.8em;
    padding: 6px 0;
    border-top: #CCCCCC33 1px dashed;
}

.jumbo-list { 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    border-radius: 12px;
}

.jumbo-list.black { 
    background-color: #00000088;
    padding: 12px;
    margin-bottom: 12px;
}

.jumbo-list.solid { 
    background-color: var(--pod-color);
    padding: 12px;
    margin-bottom: 12px;
}

.jumbo-list:last-child { 
    margin-bottom: 0;
}

.no-padding > .jumbo-list { 
    padding: 12px;
}

.grid { 
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
}

.grid table.table > tbody > tr > td:first-child,
.grid table.table > thead > tr > th:first-child {
    width: 80%;
}

.grid table.table tr td,
.grid table.table tr th { 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.grid.thirds > div { 
    width: calc(33% - 48px);
}

.grid.thirds > div > table { 
    width: 100%;
}

.title-submenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

.title-submenu > *:first-child {
    font-size: 2em;
}

.message-preview {
    font-family: Arial, Helvetica, sans-serif;
}

.jumbo-list > *:first-child { 
    margin-right: 16px;
}

.jumbo-list > h1 { 
    font-size: 118px;
    margin: 0;
    min-width: 150px;
    text-align: center;
}

.jumbo-list > h1.dynamic { 
    font-size: calc(118px - 2vw);
}

.jumbo-list > div {
    flex: 4;
}

.jumbo-list > div > ul > li {
    border-bottom: var(--border-color) 1px solid;
    padding-right: 8px !important;
    white-space: nowrap;
    overflow: hidden;
    max-width: 88%;
}

.jumbo-list > div > ul > li:last-child { 
    border-bottom-color: transparent;
}

.jumbo-list > div > ul > li > a { 
    padding: 8px 0;
    display: block;
}

.pod {
    padding: 16px;
    border-radius: 12px;
    background-color: var(--pod-color);
}

.pod.no-color { 
    background-color: transparent;
}

.pod.no-padding { 
    padding: 0;
}

.pod h4 {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 100%;
    margin: 0 0 12px 2px;
    font-size: 13px;
    opacity: 0.7;
    font-weight: bold;
}

.hidden {
    display: none;
}

.zip-file-download { 
    background-color: var(--dark-background-color);
    padding: 24px;
    border-radius: 12px;
    margin-bottom: 48px;
    
    display: flex;
    justify-content: space-between;
}

.zip-file-download > span:first-child > a {
    color: var(--yellow);
}

.zip-file-download svg { 
    margin-right: 8px;
}

.details-body h2 { 
    display: flex;
    min-height: 40px;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
}

span.submenu-delimiter { 
    display: inline-block;
    margin-left: 12px;
}

div.footer { 
   margin-top: 128px; 
}

.links { 
    
}

.links a > svg { 
    margin-right: 8px;
}

.links a.excel > svg {
    color: var(--good-green);
}


h1 .subtitle {
    display: inline-block;
    margin-right: 12px;
    vertical-align: center;
    color: var(--text-color-faded);
}

h1 .subtitle a {
    color: var(--text-color-faded);
}

h1 .subtitle a:hover {
    color: var(--text-color);
}

h2 .subtitle { 
    display: inline-block;
    margin-left: 12px;
    vertical-align: center;
    color: var(--text-color-faded);

    max-width: 300px;
    overflow: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
}

h2 .subtitle.no-limit { 
    max-width: unset;
}

h2 .pre-subtitle {
    max-width: 280px;
    overflow: clip;
    text-overflow: ellipsis;
    white-space: nowrap;

    display: inline-block;
    margin-right: 6px;
    color: var(--text-color-faded);
}

h2 .pre-subtitle + a { 
    max-width: 280px;
    overflow: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--text-color-faded);
}

h2 .pre-subtitle a {
    display: inline-block;
    color: var(--text-color-faded);
}

h2 .pre-subtitle a:hover { 
    color: var(--bright-text-color);
}

h2.tree { 
}

h2.tree span.subtitle { 
    color: white;
}

h2.tree span.subtitle a { 
    color: var(--text-color-faded);
}

a.add {
    display: inline-block;
    font-size: 13px;
    border: var(--bocg-blue-faded) 1px solid;
    color: var(--bocg-blue-faded);
    padding: 8px 12px;
    border-radius: 8px;
}

h2 a.add {
    margin-left: 56px;
    font-size: 14px;
    
    padding: 8px 12px;
    border-radius: 10px;
}

h3.total { 
    display: flex;
    align-items: end;
    align-content: end;
    justify-content: space-between;
    padding-bottom: 0;
}

h3.total > span { 
    /* */
    line-height: 24px;
}

h3.total > span.total label { 
    display: inline-block;
    margin-right: 12px;
    font-size: 24px;
    line-height: 0;
    color: var(--text-color-faded);
    padding-bottom: 0;
}

h3.total > span.total > span.boc-span.currency-text { 
    font-size: 48px;
    padding-bottom: 0;
}

a.add:hover {
    color: var(--bocg-blue);
    background-color: #00000033;
}

h2 a.back-button { 
    display: inline-block;
    clear: both;
    width: 40px;
    margin-right: 16px;
    text-align: center;
    padding: 4px;
    align-content: center;
    align-items: center;
    align-self: center;
    vertical-align: middle;
    border: var(--border-color) 1px solid;
    border-radius: 5px;
    color: var(--text-color-faded);
}

h2 a.back-button:hover {
    color: white;
}

h2 a.add svg { 
    margin-right: 8px;
}

h1 > svg {
    margin-right: 16px;
}

h2 > svg {
    margin-right: 12px;
}

h3 > svg {
    margin-right: 8px;
}

h4 > svg { 
    margin-right: 8px;
}

.details-body > div.pad > h2 > svg { 
    margin-right: 24px;
}

.dark-boxes { 
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    text-align: left;
}

.dark-boxes > div.box { 
    border-radius: 12px;
    background-color: var(--dark-background-color);
    margin: 0 32px 32px 0;
    border-width: 0;
    height: 256px;
    width: 200px;
    opacity: 0.65;
}

.dark-boxes > div.box:hover { 
    opacity: 1.0;
}

.dark-boxes > .box > h3 {
    padding-top: 14px;
    margin-bottom: 8px;
}

.dark-boxes > .box > div { 
    width: 180px;
    padding-top: 8px;
    margin-bottom: 0;
}

.dark-boxes > .box > div.icon { 
    height: 112px;
    display: flex;
    width: 200px;
    justify-content: center;
    align-content: center;
}

.dark-boxes > .box > div img { 
    width: 80px;
}

.dark-boxes > .box ul {
    text-align: left;
    padding: 0 12px 12px 12px;
}

.dark-boxes > .box ul li {
    display: block;
    font-size: 14px;
    margin-bottom: 4px;
    cursor: pointer;
}

.dark-boxes > .box ul li a { 
    display: block;
    padding: 4px 8px;
    border-radius: 6px;
}

.dark-boxes > .box ul li a:hover { 
    background-color: var(--dark-background-color);
}

div.comments { 
    clear: both;
    margin-top: 48px;
}

.container {
    /* */
}

.right { 
    float: right;
}

.hover-block,
.hover-inline,
.hover-view { 
    display: none;
}

*:hover > .hover-view {
    display: inline-block;
}

*:hover > .hover-block {
    display: block;
}

*:hover > .hover-inline {
    display: inline;
}

.top-right { 
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 12;
}

.top-right.zero {
    right: 0;
    top: 0;
}

#project-dashboard-total {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: red;
    display: none; /* Change this */
}

table.web-visitors > thead > tr > th:first-child,
table.web-visitors > tbody > tr > td:first-child {
    width: 35%;
}

table.web-visitors.no-wrap,
td.web-visitor-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    text-overflow-ellipsis: ellipsis;
}

td.web-visitor-label svg {
    margin-right: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-overflow-ellipsis: ellipsis;
}

table.web-visitors td:first-child div.session-id {
    color: var(--yellow);
    font-size: 0.8em;
}

.details-body p.no-bottom,
.details-body .details-lines + p {
    margin-bottom: 0;
    padding-bottom: 0;
}

/* Any line item on the details screen. Usually below the <p> */
.details-lines:empty { 
    display: none;
}

.details-lines > div, .details-line { 
    display: block;
    padding: 8px 0;
}

.details-lines > div strong, .details-line strong { 
    color: var(--link-color);
}

.details-lines > div:first-child, .details-line:first-child { 
    margin-top: 12px;
}

.details-lines > div:last-child, .details-line:last-child {
    margin-bottom: 12px;
}

ul.dashboard-items { 
    
}

ul.dashboard-items li {
    display: flex;
    width: 100%;
    padding: 24px;
    margin-bottom: 24px;
    justify-content: space-between;
    border: var(--border-color) 1px dashed;
    border-radius: 12px;
}

ul.dashboard-items li > span:first-child {
    display: block;
}

ul.dashboard-items li>span>span:first-child { 
    display:block;
    color: white;
    font-size: 4rem;
    padding-bottom: 4px;
    margin-bottom: 4px;
}

ul.dashboard-items > li> span.price-element > .currency-text {
    font-size: 4rem;
    font-weight: 100;
    font-family: 'Archivo Narrow', Arial, Helvetica, sans-serif;
    color: unset;
    padding-bottom: 0;
}

ul.dashboard-items>li span.date { 
    font-size: 13px;
    display: block; 
    margin-top: 12px;
}

ul.dashboard-items>li .dashboard-project-items { 
    display: flex;
    flex-wrap: wrap;
}

ul.dashboard-items>li .dashboard-project-items>span { 
    padding: 8px 12px;
    background-color: #FFFFFF33;
    border-radius: 5px;
    border: bar(--border-color) 1px solid;
    color: white;
    font-size: 1.4rem;
    margin-top: 8px;
    margin-right: 8px;
    margin-bottom: 16px;
}

ul.dashboard-items>li .dashboard-project-items>span.status-1 { 
    background-color: #FFFFFF22;
    color: #777;
}

div.dashboard-projects { 
    position: relative;
}

div.bottom-fade {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 12px;
}

span.access-form.form {
    display: flex;
    align-items: center;
    align-content: center;
    margin-top: 4px;
    height: 48px;
}

span.access-form.sent > span {
    padding: 6px 12px;
    width: 200px;
    border-radius: 6px;
    font-size: 15px;
    border: var(--light-border-color) 1px dashed;
}

span.access-form.form > span:first-child {
    margin-right: 12px;
}

span.access-form.form > select,
span.access-form.form > input[type="text"],
span.access-form.form > input[type="email"] {
    margin-top: 4px;
    margin-bottom: 0;
    margin-right: 12px;
}

p > span.tokens {
    gap: 12px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
}

p > span.tokens > span {
    cursor: pointer;
    background-color: #CCCCCC22;
    display: inline-block;
    white-space: nowrap;
    border-radius: 6px;
    padding: 4px 10px;
    font-size: 14px;
}

.message-preview {
    color: black;
    background-color: white;
    border: var(--border-color) 1px solid;
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 16px;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.message-preview * {
    color: black;
}

.details-body .message-preview .flex { 
    justify-content: flex-start;
}

.flex8 {
    gap: 8px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

span.shrink {
    display: inline-block;
    white-space: nowrap;
}

a.flex8 {
    padding: 6px 10px;
    border-radius: 6px;
}

a.black {
    background-color: black;
}

.form svg.markdown-tooltip {
    cursor: pointer;
    margin-left: 8px;
    color: var(--yellow-faded);
}

.container.dark {
    padding: 0 24px 0 16px;
    background-color: var(--dark-background-color);
    border: var(--light-border-color) 1px solid;
    height: 48px;
    border-radius: 6px;
}

.container.dark select { 
    margin-top: 6px;
    border-width: 0;
}

textarea.text-tool { 
    width: 100%;
    height: 200px;
    max-width: 1200px;
}

.long-button { 
    width: 100%;
    max-width: 1200px;
    padding-top: 24px;
    padding-bottom: 24px;
}

.view-items { 

}
.view-items> div { 
    margin-bottom: 24px;
    border: var(--border-color) 1px dashed;
    border-radius: 12px;
    padding: 16px;
    display: block;
    /* justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start; */
}

.view-items>div > p { 
    margin: 0;
}

.p-columns { 
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.p-columns *:last-child { 
    flex: 3;
}

.p-columns.even *:last-child {
    flex: unset;
}

p strong { 
    color: var(--bocg-blue);
}

p span.right {
    float: right;
}

p span.right span.view-selector { 
    display: flex;    
}

p span.right span.view-selector a { 
    display: inline-block;
    margin-left: 16px;
    padding: 8px 16px;
    border-radius: 4px;
    text-align: center;
    border: var(--light-border-color) 1px solid;
}

p span.right span.view-selector a svg { 
    width: 32px;
    color: var(--text-color);
}

p span.right span.view-selector a:hover { 
    background-color: var(--dark-background-color);
}

p span.right span.view-selector a.selected svg,
p span.right span.view-selector a:hover svg { 
    color: var(--bocg-blue);
}

#my-header {
    display: flex;
    justify-content: stretch;
    align-items: flex-end;
    position: fixed;
    z-index: 24;
    right: 32px;
    top: 0;
    left: 308px;
    height: 110px;
    background-color: var(--background-color);
    border-bottom: var(--border-color) 1px solid;
}

#my-header div.inner {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background-color: var(--background-color);
    width: 100%;
    min-width: 100%;
    padding-bottom: 28px;
}

#my-header div.inner > span { 
}

#my-header div.inner > span:last-child { 
}

#my-header div.inner #title-h1 h1 { 
    margin-bottom: 0;
}

#my-header #my-header-account-panel { 
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#my-header > div.inner > .section { 
    padding: 12px;
}

#my-header > div.inner .section #my-profile-top-menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px;
}

#my-header > div.inner .section #my-profile-top-menu-item .default-image { 
    display: inline-block;
    padding: 0;
    margin-right: 12px;
}

#my-header > div.inner .section #my-profile-top-menu-item .default-image img {
    border-radius: 70%;
    height: 42px;
    width: 42px;
    border: #FFFFFF44 2px solid;
}

#my-header > div.inner .section #my-profile-top-menu-item .default-image svg {
    border-radius: 70%;
    height: 24px;
    width: 24px;
    padding: 9px;
    background-color: black;
}

#my-header > div.inner ul li { 
    font-size: 12px;
    padding-right: 16px;
    padding-top: 3px;
}

#my-header > div.inner ul li:first-child {
    font-size: 14px;
    color: white;
}

#my-header > div.inner .section {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 128px;
    padding: 0;
    margin-left: 16px;
    height: 51px;
}

#my-header > div.inner .section .content {
    display: flex;
}

#search-slider { 
    width: 500px !important;
    align-content: end;
    text-align: right;
    display: block !important;
    overflow-x: hidden;
    overflow-y: visible !important;
    position: relative;
}

#my-header > div.inner #main-search-section.section { 
    margin-left: 0;
}

.search-sled { 
    color: white;
    display: inline-block;
    padding-right: 16px;
}

.search-sled #master-search-container { 
    height: 52px;
    padding-top: 9px;
    padding-left: 18px;
    background-color: black;
    border-top-left-radius: 28px;
    border-bottom-left-radius: 28px;
}
    
.search-sled #master-search-container > div {
    line-height: 0;
}

.search-sled .x-large { 
    width: 500px;
    min-width: unset;
    line-height: 0;
}

.search-sled #master-search-container .search-box { 
    width: 460px;
    font-size: 14px;
    margin-bottom: 0;
}

#my-header > div.inner .section .content.circle {
    width: 51px;
    height: 51px;
    align-content: center;
    align-items: center;
    justify-content: center;
    position: relative;
    user-select: none;
}

.section-x { 
    display: inline-block;
}

#my-header > div.inner .section .content.circle .badge {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 0;
    background-color: rgba(190, 0, 0, 0.5);
    color: white;
    border: rgba(255, 255, 255, 0.5) 1px solid;
    padding: 7px 3px;
    font-size: 10px;
    font-weight: bold;
    border-radius: 50%;

    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 28px;
    width: 28px;
    filter: drop-shadow(0px 2px 4px black);
}

#my-header > div.inner .section .content.notifications .badge .badge-content {
    display: inline-block;
}

#my-header > div.inner .section .content #notifications-top,
#my-header > div.inner .section .content .top-circle {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    /* background-color: rgba(0, 0, 0, 0.4); */
    border-radius: 50%;
}

#my-header > div.inner .section .content #notifications-top svg {
    width: 24px;
    height: 24px;
}

#my-header > div.inner .section .content #notifications-top:hover svg {
    color: white;
}

#my-profile-image-top {
    border-radius: 50%;
    padding: 2px 2px 3px 4px;
    height: 48px;
    width: 48px;
}

div.box {
    margin: 10px auto;
    text-align: center;
    border: #CCC 1px solid;
    display: inline-block;
    min-width: 200px;
}

#logo { 
    display: block;
    text-align: center;
    padding-bottom: 24px;
    border-bottom: #FFFFFF22 1px solid;
    margin: 24px;
    padding-top: 12px;
    opacity: 0.6;
}

#logo svg { 
    height: 64px;
}

#logo img { 
    height: 64px;
}

#logo:hover {
    opacity: 1.0;
}

#logo label { 
    display: block;
    font-size: 12px;
    padding-top: 24px;
    padding-bottom: 16px;
}

div.box > div { 
    padding: 24px;
    display: inline-block;
}

.error-box {
    padding: 16px;
    color: var(--text-error-color);
    border-radius: 8px;
    background-color: var(--error-red-background);
    display: inline-block;
    text-align: left;
}

.error-box:empty { 
    display: none !important;
}

.error-box.bottom {
    margin-top: 16px;
}

.error-box.top {
    margin-bottom: 16px;
}

.error-box.wide { 
    display: block;
}

.pi-modal-dialog-pp-content { 
    max-width: 380px;
}

.pi-modal-container.dialog { 
    transition-timing-function: var(--transition-type-spring);
}

.pi-modal-container.dialog>.pi-modal-body h2 { 
    color: var(--dark-text-color);
    display: block;
    width: 100%;
    min-width: 380px;
    padding-bottom: 18px !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 16px !important;
}

.pi-modal-container { 
    color: var(--dark-text-color);
}

.pi-modal-container.dialog>.pi-modal-body>.pi-modal-content { 
    margin-bottom: 32px !important;
}

.pi-modal-container.dialog>.pi-modal-body { 
    padding: 1.75rem !important;
}

.delete-button { 
    transition-property: opacity;
    transition-duration: 200ms;
    opacity: 0.48;
    border: var(--delete-button-border-red) 1px solid;
    color: var(--delete-button-red);
    padding: 8px 16px;
    border-radius: 8px;
}

.delete-button:hover { 
    opacity: 1;
}

.search-box { 
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 24px;
}

.form.search-box > button { 
    margin: 0;
    padding: 12px 24px;
}

.form select,
.form input {
    margin-bottom: 8px;
}

.form label {
    display: inline-block;
    clear: both;
    padding-bottom: 8px;
    padding-top: 16px;
}

.form label > label,
.details-body .form label > label {
    font-size: 12px;
    margin-top: -10px;
    padding-bottom: 0;
    color: var(--text-color-faded);
}

.details-body .form label.details,
label.details { 
    display: block;
    font-size: 14px;
    padding-top: 8px;
    margin-bottom: 32px;
}

div.buttons {
    padding: 16px 0;
}

select,
textarea,
input[type="text"],
input[type="url"],
input[type="number"],
input[type="date"],
input[type="password"],
input[type="datetime-local"] {
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid var(--border-color);
    color: var(--input-text-color);
    padding: 8px 24px 8px 8px;
    font-family: Arial, Helvetica, sans-serif;
    background-color: var(--input-background-color);
    color-scheme: dark;
}

select,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    color: var(--text-color) !important;
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    -webkit-text-fill-color: var(--input-text-color) !important;
    color-scheme: dark;
    background-color: #00000000 !important;
}

input[type="checkbox"] { 
    
}

input[type="text"].description { 
    min-width: 300px;
}

.form input[type="text"].description { 
    width: 100%;
}

textarea:focus,
select:focus,
input:focus {
    outline: rgba(255, 255, 255, 0.4) 1px solid;
    background-color: black;
    color-scheme: dark;
}

#message-template-body { 
    min-width: 640px;
    min-height: 220px;

    width: 100%;
    height: 50vh;
    font-family: var(--code-font);
    font-size: 1rem;
    line-height: 1.5;
    color: var(--code-color);
}

#template-form.wide .form-group input {
    font-size: 1.1rem;
}

#message-template-name-group { 
    margin-bottom: 32px;
}

button { 
    padding: 8px 14px;
    background-color: #444;
    color: white;
    font-size: 14px;
    border-radius: 6px;
    cursor: pointer;
    border-width: 0;
}

#message-element .markdown.has-details { 
    border: #222 1px solid;
}

.details-body #message-element .form.medium.project-item textarea { 
    width: 100% !important;
    min-width: 100% !important;
}

button#dark-button > div > span:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    transition-property: margin-left, opacity, width;
    transition-duration: 300ms;
    width: 0;
    opacity: 0.0;
    margin-left: 0;
}

button#dark-button.spinning>div>span:last-child { 
    margin-left: 8px;
    opacity: 1.0;
    width: unset;
}

button:hover { 
    background-color: var(--button-hover-color);
}

button.action:hover { 
    background-color: var(--button-action-hover-color);
}

button > div { 
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
}

button.dark-button { 
    font-size: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
}

button.dark-button > div > span:first-child,
button.dark-button > div > span:last-child { 
    width: 16px;
    height: 16px;
}

button.dark-button > div > span:first-child {
    margin-right: 8px;
}

button.dark-button > div > span:last-child {
    margin-left: 8px;
}

ul.meta-details > li { 
    padding: 6px 0;
}

ul.meta-details>li > span,
ul.meta-details>li>div {
    display: flex;
    justify-content: flex-start;
    gap: 24px;
}

ul.meta-details>li>*>*:first-child { 
    display: inline-block;
    width: 100px;
}

ul.meta-details>li.dialog-click { 
    cursor: pointer;
    color: var(--link-color);
    border: var(--border-color) 1px solid;
    display: inline-block;
    text-align:center;
    padding: 8px 12px;
    border-radius: 6px;
    margin: 12px 0;
}

tr.payment-status-1>td,
tr.payment-status-1>td>a {
    /* */
}

tr.payment-status-2>td:first-child,
tr.payment-status-2>td:last-child,
tr.payment-status-2>td>a {
    color: var(--success-light-green);
}

tr.payment-status--1 > td:first-child,
tr.payment-status--1>td:last-child,
tr.payment-status--1>td > a {
    color: var(--error-code-color);
    opacity: 0.75;
}

.pi-modal-container.menu.context>.pi-modal-body,
.pi-modal-container.menu.context.bottom>.pi-modal-body { 
    background-color: var(--background-color)!important;;
    color: var(--text-color) !important;
    border:var(--border-color) 1px solid;
}

.pi-modal-container.menu.context>.pi-modal-body>.dropdown.menu-item { 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 6px 12px 6px 0;
    background-color: transparent;
    cursor: pointer;
    border-bottom-color: var(--border-color) !important;
}

.pi-modal-container.menu.context>.pi-modal-body>.dropdown.menu-item:hover { 
    background-color: var(--line-hover-background-color);
}

.file-upload-container { 
    
}

.file-selector-image { 
    
}

.user-submenu { 
    padding-top: 24px;
}

.details-body .sub-column.user-submenu .file-selector-detail-list { 
    display: none;
}

.user-submenu .file-upload-container .file-upload-form-elements {
    display: none;
}

.user-submenu .file-upload-container.selected .file-upload-form-elements {
    display: block;
    padding-top: 12px;
    text-align: center;
}

.user-submenu .file-upload-container .file-upload-form-elements input[type="file"] { 
    display: none;
}

.user-submenu .file-upload-cancel { 
    display: block;
    margin-top: 8px;
    margin-bottom: 8px;
}

.user-submenu .file-selector-image {
    text-align: center;
}

.user-submenu .file-selector-image span {
    background-color: #00000033;
    display: inline-block;
    overflow: hidden;
    border-radius: 50%;
    border: var(--border-color) 4px solid;
    width: 152px;
    height: 152px;
}

.user-submenu .file-selector-image span img { 
    max-height: 148px;
    max-width: 148px;
}

.user-submenu .file-selector a .file-selector-image span svg {
    height: 64px;
    width: 64px;
    margin-top: 32px;
    color: var(--text-color-faded);
    
    max-height: 148px;
    max-width: 148px;
}

.user-submenu .file-selector a:hover .file-selector-image span svg {
    color: var(--bright-text-color);
}

.spinner { 
    width: 16px;
    height: 16px;
    display: inline-block;
}

label.spinning,
button.spinning > div > span label.spinner {
    margin: 0;
    border: 3px solid #FFFFFFAA;
    border-top: 3px solid #00000000;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    animation: spin 0.4s linear infinite;
    padding: 0;
}

.form.large {
    width: 800px;
}

.form.medium {
    width: 600px;
}

.form.small {
    width: 400px;
}

.form.large div.buttons,
.form.medium div.buttons { 
    margin-top: 32px;
    margin-bottom: 24px;
}

.form.large h3,
.form.medium h3,
.form.small h3 { 
    margin-top: 32px;
    margin-bottom: 8px;
    padding: 0;
}

.form .double,
.form .triple { 
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
}

.form .double > div.half {
    flex-basis: 48%;
}

.form .double > div.majority {
    flex-basis: 68%;
}

.form .double > div.minority {
    flex-basis: 28%;
}

.form div.third {
    flex: 1;
}

.form .triple > div.majority {
    flex: 3;
}

.form .triple > div.minority {
    flex: 1;
}

.form .double div.half select,
.form .double div.half input { 
    width: 100%;
}

.form .double > div:first-child {
    margin-right: 8px;
}

.form .double > div:last-child {
    margin-left: 8px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.form .wide input[type="text"],
.form.wide input[type="text"],
.form .wide input[type="phone"],
.form.wide input[type="phone"],
.form .wide input[type="email"],
.form.wide input[type="email"] {
    width: 100%;
    display: block;
}

.form .form-error { 
    padding-top: 2px;
    padding-bottom: 24px;
    font-weight: normal;
    line-height: 150%;
    font-size: 16px;
    display: block;
    color: var(--text-error-color);
}

.form .form-error:empty { 
    display: none;
}

.form .form-error.show:empty { 
    display: block;
}

.form .form-error.show:empty::after {
    content: ' x';
    color: transparent;
}

.top-border { 
    border-top: var(--border-color) 1px solid;
    padding-top: 24px;
    margin-top: 24px;
}

.top-border.dashed { 
    border-top-style: dashed;
}

.pad .medium { 
    width: 600px;
}

div.submenu-flexer { 
    display: flex;
    justify-content: space-between;
}

.submenu { 
    display: block;
}

.submenu > a { 
    display: inline-block;
    padding-bottom: 12px;
    margin: 0 32px 32px 0;
    color: var(--text-color-faded);
    border-bottom: transparent 4px solid;
}

.submenu > a.selected,
.submenu.disabled > a.selected:hover {
    color: var(--text-color);
    border-bottom-color: var(--bocg-blue);
}

.submenu > a:hover {
    color: white;
    border-bottom-color: var(--bocg-blue);
}

.submenu.disabled > a:hover {
    color: var(--text-color-faded);
    border-bottom: transparent 4px solid;
    cursor: default;
}

.submenu.disabled > a.selected:hover { 
    cursor: pointer;
}

.details-body .form label {
    display: block;
    padding-bottom: 12px;
}

.details-body .form input::placeholder { 
    color: var(--text-color-faded);
}

.details-body .form.medium textarea,
.details-body .form.medium input[type="text"],
.details-body .form.medium input[type="url"],
.details-body .form.medium input[type="password"],
.details-body .form.medium input[type="date"],
.details-body .form.medium input[type="datetime-local"],
.details-body .form.medium select {
    display: block;
    width: 100%;
}

.details-body .form.medium.project-item textarea { 
    min-height: 300px;
    min-width: 800px;
}

.details-body .form.medium textarea:disabled,
.details-body .form.medium input[type="text"]:disabled,
.details-body .form.medium input[type="password"]:disabled,
.details-body .form.medium input[type="date"]:disabled,
.details-body .form.medium input[type="datetime-local"]:disabled,
.details-body .form.medium select:disabled {
    color: var(--text-color-faded);
}

.details-body .form.medium textarea { 
    min-height: 90px;
}

#main {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    flex-wrap: wrap;
    height: 100vh;
    min-height: 100vh;
}   

#main-column {
    background-color: var(--main-column-background-color);
    width: 260px;
    min-height: 100vh;
    height: 100%;
    top: 0;
    bottom: 0;
    display: block;
    position: fixed;
}

#main-column #main-column-scroller { 
    overflow-y:auto;
    overflow-x: hidden;
}

ul.ul {
    list-style: none;
}

ul.ul,
#main-column ul {
    display: block;
    padding: 12px;
}

ul.ul li,
#main-column ul li {
    display: block;
    background-repeat: no-repeat;
    background-position: left center;
    margin-bottom: 24px;
    font-size: 14px;
}

#main-column ul li span {
    display: inline-block;
}

#main-column ul li a {
    display: block;
    padding: 16px;
    margin-left: 8px;
    border-radius: 5px;
    border-left: transparent 12px solid;
    opacity: 0.8;
}

#main-column ul li.selected a {
    background-color: rgba(255, 255, 255, 0.1);
    border-left-color: var(--bocg-blue);
    opacity: 0.8;
}

#main-column ul li a:hover,
#main-column ul li.selected a:hover {
    background-color: rgba(255, 255, 255, 0.15);
    border-left-color: var(--bocg-blue);
    opacity: 1.0;
    transition-duration: 200ms;
    transition-property: background-color;
}

#main-column ul li a svg {
    margin-right: 12px;
    opacity: 0.5;
}

#main-column ul li a:hover svg {
    margin-right: 12px;
    opacity: 1.0;
}

#main-column ul#mini-menu li {
    font-size: 12px;
}

#main-column ul#mini-menu li a {
    margin-left: 0;
}

#main-column ul#mini-menu li a svg {
    color: transparent;
}

#main-column ul#mini-menu li a:hover {
    background-color: transparent;
    border-color: transparent;
    color: yellow;
}

#main-column ul#mini-menu li a:hover svg {
    display: inline-block;
    color: yellow;
}

#main-body {
    display: block;
    background-color: var(--background-color);
    width: calc(100vw - 260px); /* 100% of the view width, -minus the width of the left menu colum (260) */
    margin-left: 260px;
    margin-top: 63px;
}

#main-body-login { 
    text-align: center;
    display: block;
    margin: auto;
}

#main-body-login.complete { 
    margin-top: 128px;
}

#main-body-login.complete div.logo img { 
    max-width: 128px;
    max-height: 128px;
}

#main-body-login .box {
    align-content: center;
    align-items: center;
    align-self: center;
    width: 500px;
    border-radius: 16px;
    
    border: #FFFFFF20 1px solid;
}

#main-body-login .box > div { 
    display: inline-block;
    text-align: left;
}

#main-body-login .box > div .error-box { 
    width: 300px;
    line-height: 20px;
    font-size: 14px;
}

ul.section-panels { 
    
}

ul.section-panels > li { 
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: var(--border-color) 1px dashed;
}

ul.section-panels > li:last-child { 
    border-bottom-width: 0;
}

ul.section-panels > li > span { 

}

ul.section-panels > li > span.icon {
    width: 128px;
    text-align: center;
}

ul.section-panels > li > span.icon svg { 
    min-width: 42px;
    min-height: 42px;
}

ul.section-panels > li > span.content {
    flex: 4;
    padding-right: 16px;
    padding-left: 24px;
}

ul.section-panels > li > span.content > h3 { 
    margin-bottom: 8px;
}

ul.section-panels > li > span.content > p {
    margin-bottom: 0;
}

ul.section-panels > li > span.value {
    
}

ul.section-panels > li > span.value > button.dark-button {
    min-width: 160px;
}

.form .error-item { 
    color: var(--error-red);
}

#main-body-login .box > div > label { 
    width: 300px;
}

#main-body-login .box > div input { 
    width: 300px;
    display: block;
}

#main-body-login .box > div div.buttons { 
    padding-top: 32px;
}


.MuiTooltip-popper.notifications-tooltip-popper {
    padding-top: 20px !important;
    padding-right: 12px !important;
}

.MuiTooltip-tooltip.notifications-tooltip {
    max-width: 420px !important;
    border-radius: 12px !important;
    /*background-color: rgba(0, 0, 0, 0.4) !important;*/
    background-color: var(--main-column-background-color) !important;
}

#notifications-box { 
    width: 340px;
    border-radius: 12px;
}

#notifications-box #notifications-item-list li {
    border-bottom: var(--border-color) 1px solid;
}

#notifications-box #notifications-item-list li > a { 
    display: flex;
    padding: 12px 12px;
    margin: 8px 12px;
    align-items: flex-start;
    cursor: pointer;
}

#notifications-box #notifications-item-list li:last-child { 
    border-bottom: none;
}

#notifications-box #notifications-item-list li:hover > a { 
    background-color: #CCCCCC11;
    border-radius: 8px;
    border-bottom-color: transparent;
}

#notifications-box #notifications-item-list li > a > span { 
    font-size: 14px;
}

#notifications-box #notifications-item-list li > a > span:first-child {
    display: inline-block;
    align-self: center;
    width: 38px;
}

#notifications-box #notifications-item-list li > a > span:first-child svg { 
    min-height: 24px;
}

#notifications-box #notifications-item-list li.un-read > a > span:first-child svg { 
    color: var(--bocg-blue);
}

#notifications-box #notifications-item-list li > a > span > strong { 
    display: block;
    padding-bottom: 6px;
    font-size: 15px;
    color: white;
}

#notifications-box #notifications-item-list li > a > span > span { 
    display: block;
}

#notifications-box #notifications-item-list li > a > span > span:last-child { 
    font-size: 11px;
    padding-top: 6px;
    color: rgba(255, 255, 255, 0.5);
}

.MuiTooltip-arrow {
    /*color: #000000CC !important;*/
    color: var(--main-column-background-color) !important;;
}

.MuiTooltip-tooltip {
    /*background-color: #000000CC !important;*/
    background-color: var(--main-column-background-color) !important;;
    margin-right: 24px !important;
    font-size: 14px !important;
}

.details-panel { 
    display: flex;
    margin-bottom: 24px;
    justify-content: space-between;
}

.details-panel > .action-panel { 
    font-size: 13px;
}

.details-body .details-panel > p { 
    display: inline-block;
    padding-left: 12px;
    color: white;
    margin-bottom: unset;
}

.details-panel > .rounded-box { 
    /* background-color: rgba(0, 0, 0, 0.2); */
    background-color: var(--panel-color);
    padding: 24px 32px 24px 24px;
    margin-right: 24px;
    border-radius: 16px;
    line-height: 1.75;
}

.details-panel > .rounded-box > ul { 
    padding: 0;
    display: block;
}

table.table-logs > tbody > tr:hover,
table.table-logs > tbody > tr:hover > td > p { 
    color: white;
}

table.table-logs > tbody > tr > td { 
}

table.table-logs > tbody > tr:hover > td {
}

table.table-logs tr th:last-child,
table.table-logs tr td:last-child { 
    width: 10%;
}

table.table-logs td p { 
    margin: 0;
    padding: 6px;
    background-color: var(--column-background-color);
    border-radius: 8px;
    display: block;
    font-size: 12px;
}

table.table-logs tr:hover td p { 
    color: white;
}

table.table-logs td ul li:first-child { 
    display: flex;
    gap: 24px;
    margin-bottom: 8px;
}

table {
    padding: 0;
    margin: 0;
    table-layout: fixed;
    border-collapse: collapse;
}

table td { 
    overflow-x: clip;
    overflow-y: clip;
    text-overflow: fade;
}

table tr > td.td-check { 
    width: 48px;
}

table td:hover {
}

table td.multi-line > span { 
    display: block;
}

table td.multi-line > span:last-child { 
    font-size: 12px;
    margin-top: 5px;
    color: var(--connected-green);
}

table td.multi-line > span:last-child:empty { 
    display: none;
}

tr.drag-sort td:first-child svg {
    margin-right: 8px;
    color: var(--text-color-faded);
}

tr.discount > td { 
    color: var(--connected-green);
}

tr.disabled > td,
tr.disabled>td a,
tr.disabled>td strong {
    color: var(--text-color-faded);
}

tr.disabled>td input,
tr.disabled>td select,
tr.disabled>td svg,
tr.un-selected>td svg,
tr.disabled>td textarea { 
    opacity: 0.5;
}

tr.un-selected>td strong { 
    color: var(--text-color);
}

tr.soft-selected>td {
    color: white;
}

table#billing-table > thead > tr > th,
table#billing-table > tbody > tr > td {
    width: 20%;
}

table#billing-table > thead > tr > th:first-child,
table#billing-table > tbody > tr > td:first-child {
    width: 50%;
    font-size: unset;
}

table#billing-table td.price {
    font-size: 1.5em;
}

table#billing-table td:first-child > span,
table#billing-table td:first-child > a { 
    font-size: 12px;
    display: block;
    margin-bottom: 6px;
}

table#billing-table td:first-child svg {
    margin-right: 6px;
}

table#billing-table td:first-child > span:first-child {
    font-size: 18px;
    color: white;
    display: block;
    margin-bottom: 12px;
}

table#billing-table td:last-child > span:last-child { 
    padding-bottom: 0;
}

table > tbody > tr.inactive > td,
table > tbody > tr > td.inactive { 
    opacity: 0.65;
}

table > tbody > tr.inactive:hover > td,
table > tbody > tr:hover > td.inactive {
    opacity: 1.0;
}

table.users-table td:first-child svg:first-child { 
    color: var(--yellow);
    margin-right: 8px;
}

table.users-table td svg:last-child {
    color: var(--yellow);
    margin-left: 8px;
}

.details-panel ul li span.not-paid {
    color: var(--error-red);
}

.details-panel ul li span.paid {
    color: var(--good-green);
}

table th,
table td {
    text-align: left;
    padding: 12px 6px;
    border-bottom: var(--border-color) 1px solid;
}

table th {
    font-size: 13px;
    color: var(--bocg-blue);
    font-weight: bolder;
    opacity: 0.8;
}

table th a {
    color: var(--bocg-blue);
    cursor: pointer;
    text-decoration: none;
}

table thead > tr.h3 th {
    border-width: 0;
    color: var(--bright-text-color);
}

table thead > tr.h3 th h3 {
    color: var(--bright-text-color);
}

table thead > tr.h3 th:first-child svg { 
    margin-right: 8px;
    color: var(--bright-text-color);
}

table thead > tr.h3 th:last-child { 
    text-align: unset;
    font-size: 18px;
}

table.full tr td > a,
table tr.full td > a {
    display: block;
}

table tr.dragging td { 
    background-color: var(--dark-background-color);
    opacity: 0;
}

table.full tr:hover td,
table tr.full:hover td {
    background-color: var(--bocg-blue-bg);
    cursor: pointer;
}

table th:last-child,
table td:last-child { 
    text-align: right;
}

table tr td.small {
    font-size: 12px;
}

table tr td.spacer { 
    height: 100px;
}

ul.analytics-details {
    display: block;
    padding: 0;
}

ul.analytics-details > li { 
    display: block;
    padding: 8px 0;
}

ul.analytics-details > li.authed-item {
    color: var(--connected-green);
}

ul.analytics-details > li.authed-item > strong {
    color: white;
}

.user-agent-section { 
    background-color: #AAAAAA11;
    border-radius: 12px;
    margin-top: 24px;
    padding: 16px
}

.user-agent-section pre { 
    font-size: 12px;
    font-family: "Courier New", Courier, monospace;
    white-space: pre-wrap;
}

.user-agent-section > h4 { 
    margin-bottom: 8px;
}

.price-update { 
    width: 92px;
}

#companies-table tr td.spacer { 
    border-bottom-width: 0;
}

.pad {
    padding: 48px 16px 16px 48px;
}

.sub-column + .pad { 
    flex: 1;
}

.clickable { 
    cursor: pointer;
}

table tr.clickable:hover td { 
    background-color: var(--line-hover-background-color);
}

.large {
    width: 800px;
    max-width: 1000px;
}

table.wide {
    width: 100%;
    max-width: 100%;
}

.x-large {
}

.dim-border-bottom {
    border-bottom: rgba(255, 255, 255, 0.1) solid 1px;
}

.dim-border-top {
    border-top: rgba(255, 255, 255, 0.1) solid 1px;
}

.details-body {
    display: flex;
    position: relative;
    align-items: stretch;
    align-content: stretch;
    flex-wrap: nowrap;
    /* border-top: rgba(255, 255, 255, 0.1) solid 1px; */
    width: available;
    margin-right: 16px;
}

.dark-button-tooltip .checkbox { 
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
}

.dark-button-tooltip .checkbox .checkbox-icon > svg {
    font-size: 1em !important;
}

.details-body .flex { 
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
}

.details-body p { 
    clear: both;
}

.details-body p > span.notice { 
    display: block;
}

.details-body p > span.notice > svg { 
    color: var(--yellow);
    margin-right: 8px;
}

.details-body p > span.notice > a { 
    color: var(--yellow);
}

.details-body p > span.identifier { 
    border: var(--bocg-blue-faded) 1px solid;
    color: var(--bocg-blue);
    padding: 6px 12px;
    margin-right: 16px;
    display: inline-block;
    border-radius: 8px;
}

.details-body .flex > p { 
    margin: 0 auto 0 0;
}

.details-body p.with-filter { 
    width: 100%;
    min-width: 100%;
    margin-right: unset;
}

.details-body p a,
a.link {
    color: var(--bocg-blue);
}

.details-body p a:hover {
    color: white;
    text-decoration: underline;
}

.details-body .section-bottom { 
    margin-top: 64px;
    margin-bottom: 16px;
}

.details-body .section {
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding: 12px 24px;
    margin-bottom: 32px;
}

.details-body .section-wrap {
    display: flex;
    align-items: start;
    justify-content: start;
    margin-bottom: 48px;
}

.details-body .section-wrap .dark {
    margin-right: 24px;
    padding: 24px;
}

.details-body #batch-result-details.section-wrap {
    font-size: 14px;
}

.details-body .section-wrap .date-details {
    padding: 6px 0;
    margin-right: 48px;
    color: rgba(255, 255, 255, 0.75);
}

div.empty-items-box {
    display: block;
    text-align: left;
    padding: 0;
    color: var(--text-color);
    background-color: #55555511;
    border: var(--light-border-color) 1px solid;
    max-width: 600px;
    line-height: 1.5;
    margin-top: 48px;
    margin-bottom: 32px;
}

.profile-image { 
    display: inline-block;
    padding: 0;
    background-color: var(--border-color);
    border-radius: 100%;
    line-height: 0;
    overflow: clip;
    text-align: center;
}

.profile-image > img { 
    --h: 64px;
    border-radius: 100%;
    margin: 3px;
    width: var(--h);
    height: var(--h);
    max-height: var(--h);
    max-width: var(--h);
    min-width: var(--h);
    min-height: var(--h);
    object-fit: cover;
}

.MuiDialog-paperScrollPaper span.form-group { 
    
}

.MuiDialog-paperScrollPaper span.form-group > label {
    display: block;
    padding-bottom: 6px;
}

.MuiDialog-paperScrollPaper h2.completed-title { 
    text-align: center !important;
    display: block !important;
    margin-right: 0 !important;
    min-width: 400px !important;
    margin-top: 12px;
}

.dialog-buttons.completed { 
    text-align: center;
    width: 400px;
    display: block !important;
    padding-bottom: 12px;
}

.completed-panel { 
    text-align: center;
}

#main-body-login.complete .box .completed-panel {
    padding-top: 32px;
    padding-bottom: 32px;
    display: inline-block;
}

.completed-panel > svg { 
    margin-top: 12px;
    width: 64px;
    height: 64px;
    background-color: var(--text-color);
    border: var(--text-color-faded) 2px solid;
    color: green;
    border-radius: 50%;
}

.empty-items-box > div {
    padding: 32px 48px;
}

.empty-items-box a { 
    text-decoration: underline;
}

.empty-items-box a:hover { 
    color: white !important;
}

.details-body p {
    margin-bottom: 48px;
    line-height: 1.75;
    color: rgba(255, 255, 255, 0.65);
    max-width: 720px;
    margin-right: 200px;
}

.details-body p.full, div.full {
    max-width: unset;
    margin-right: unset;
    width: 100%;
}


.details-body .sub-column {
    border-right: var(--border-color) 1px solid;
    width: 200px;
    min-width: 200px;
    min-height: calc(100vh - 1px); /* 100% of the view height, -minus the height of the top menu (100) */
    background-color: var(--column-background-color);
    display: block;
    font-size: 13px;
    position: sticky;
    bottom: 0;
}

.details-body .stage-dropdown {
    display: inline-block;
    padding-left: 16px;
}

.details-body > div.pad.x-large { 
    width: 1100px;
}

.details-body .sub-column ul {
    display: block;
    padding: 12px;
    list-style: none;
}

.details-body .sub-column ul li {
    display: block;
    border-bottom: rgba(255, 255, 255, 0.1) solid 1px;
}

.details-body .sub-column ul li.sub-menu-header {
    color: var(--bocg-blue);
    font-weight: bolder;
    padding: 12px 8px 12px 8px;
}

.details-body .sub-column ul li svg {
    display: inline-block;
    margin-right: 12px;
}

.details-body .sub-column ul > li > a > svg { 
    display: inline-block;
    width: 20px;
}

.details-body .sub-column ul li a,
.details-body .sub-column ul li span {
    display: block;
    padding: 12px 8px 12px 8px;
}

.details-body .sub-column ul li a {
    opacity: 0.7;
}

.details-body .sub-column ul li.selected a {
    opacity: 1.0;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.3);
}

.details-body .sub-column ul li.selected a svg {
    color: var(--bocg-blue);
}

.details-body .sub-column ul li.selected a:hover {
    opacity: 1.0;
}

.details-body .sub-column ul li a:hover {
    opacity: 1.0;
    background-color: rgba(255, 255, 255, 0.05);
}

.details-body div.invoice-items-edit { 
    margin-top: 12px;
}

.details-body div.invoice-items-edit .add { 
    margin-top: 12px;
}

.upload-menu a.selected { 
    color: var(--bocg-blue);
}

.copy-text { 
    cursor: pointer;
}

.copy-text svg:last-child { 
    margin-left: 8px;
}

.copy-float {
    position: absolute;
    color: white;
    top: 8px;
    right: 12px;
}

.copy-float .copy-text { 
    font-size: 14px;
    color: var(--text-color);
}

.copy-float .copy-text:hover {
    color: white;
}

#company-invoices table tr td.sent { 
    color: var(--yellow);
    font-size: 14px;
}

#company-invoices table tr td.paid {
    color: var(--good-green);
    font-size: 14px;
}

div.padding-bottom-32 { 
    padding-bottom: 32px;
}

a.button-link { 
    display: inline-block;
    margin-left: 12px;
    font-size: 13px;
}

td.delete-cell { 
    
}

td.delete-cell span.delete-cell { 
    display: none;
    font-size: 12px;
}

span.delete-cell a { 
    color: var(--delete-link-color);
}
span.delete-cell a:hover { 
    text-decoration: underline;
}

td.delete-cell:hover span.delete-cell {
    display: inline-block;
}

td.delete-cell:hover span.non-delete-cell {
    display: none;
}

a.delete {
    color: var(--delete-button-color-light);
    font-size: 13px;
    display: inline-block;
    padding: 6px 12px;
    border-radius: 5px;
    background-color: var(--delete-button-color);
}

a.delete:hover { 
    color: pink;
}

a.dark-link, a.action-button {
    padding: 6px 12px;
    display: inline-block;
    background-color: var(--dark-background-color);
    border-radius: 4px;
}

a.dark-link, a.action-button.delete svg { 
    margin-left: 6px;
}

a.dark-link, a.action-button.delete {
    background-color: var(--delete-button-color);
}

a.dark-link, a.action-button.delete:hover {
    background-color: var(--delete-button-hover-color);
}

a.dark-link:hover, a.action-button:hover {
    background-color: black;
    color: white;
}

a.action-button {
    padding: 8px 16px;
}

table tr td.remove-line-item > span { 
    display: flex;
    align-content: center;
    text-align: center;
    align-items: center;
    justify-content: flex-end;
}

table tr td.remove-line-item a { 
    display: none;
    border-radius: 15px;
    background-color: var(--error-red-background);
    color: var(--text-color);
    height: 18px;
    width: 18px;
    text-align: center;
    margin-right: 12px;
}

table tr:hover td.remove-line-item a { 
    display: inline-block;
}

table tr td.remove-line-item a:hover {
    background-color: var(--error-red-background-hover);
}

table tr td > span.inactive { 
    font-size: 13px;
    display: inline-block;
    color: var(--yellow);
    margin-left: 8px;
}

div.invoice-edit-item { 
    display: flex;
    justify-content: start;
    gap: 24px;
    align-content: center;
    align-items: center;
    padding: 8px 0;
    width: 100%;
}

div.invoice-edit-item > span > label { 
    font-size: 14px;
}

div.invoice-edit-item > span.name > input { 
    width: 240px !important;
    flex-basis: auto;
}

div.invoice-edit-item > span.done a.add { 
    margin-top: 36px;
}

div.invoice-edit-item > span.done a.add svg { 
    margin-right: 8px;
}

div.invoice-edit-item > span.price input,
div.invoice-edit-item > span.qty input { 
    width: 80px !important;
}

#invoice-details { 
    margin-top: 24px;
}

#invoice-details > label { 
    margin-top: 12px;
}

#invoice-status {
    min-width: 300px;
}

.details-body p#invoice-address {
    margin-right: 12px;
}

#invoice-actions {
    text-align: right;
    width: 156px;
    min-width: 156px;
}

.dark-section { 
    background-color: rgba(0, 0, 0, 0.2);
    padding: 24px;
    border-radius: 16px;
}

button svg { 
    margin-right: 12px;
}

.dialog-buttons { 
    display: flex;
}

.dialog-action-panel { 

}

.dialog-action-panel > span {
    display: flex;
    min-width: 200px;
    margin-right: 48px;
}

.dialog-buttons .dialog-action-panel > span > a.action-button { 
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 6px;
}

.buttons.padded { 
    padding-top: 48px;
    padding-bottom: 48px;
}

div.total { 
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding-top: 24px;
    padding-bottom: 24px;
    
}

div.total > span {
    width: 100%;
    font-size: 14px;
    display: inline-block;
    text-align: right;
    padding-bottom: 8px;
}

div.total > span.total,
div.total > strong { 
    font-size: 42px;
    font-weight: normal;
    color: white;
    margin-left: 16px;
}

td.no-td { 
    border-bottom-width: 0;
}

table.unfixed { 
    table-layout: unset;
}

table.unfixed tr td.date {
    width: 170px !important;
}

table.unfixed tr td.date-time {
    width: 210px !important;
}

table.unfixed tr td.one-line { 
    white-space: nowrap;
}

table.unfixed tr td.medium-name {
    min-width: 500px !important;
}

table.invoice tr td {
    width: 20%;
}

table.invoice tr.invoice-line-item td { 
    font-size: 20px;
    width: 16%;
}

table.invoice tr td:first-child,
table.invoice tr th:first-child {
    width: 50%;
}

table.invoice tr.invoice-line-item td:first-child > span { 
    display: block;
    width: 400px;
}

div.invoice-items { 
    margin-bottom: 48px;
}

tr.send-item-true td svg { 
    color: var(--bocg-blue);
    margin-right: 12px;
}

tr.send-item-false td svg {
    color: var(--error-red);
    margin-right: 12px;
}

tr.roadmap-header-tr td {
    background-color: var(--dark-background-color);
    font-size: 20px;
}

tr.roadmap-header-tr td.h { 
    font-size: 12px;
    font-weight: bold;
    color: var(--bocg-blue);
}

tr.roadmap-header-tr td.h:last-child { 
    padding-right: 12px;
}

tr.roadmap-header-tr td > svg { 
    margin-right: 12px;
    margin-left: 12px;
}

tr.roadmap-header-tr.un-estimated td {
    color: var(--error-red);
}

tr.roadmap-header-tr.estimated td {
    color: var(--bocg-blue);
}

ul.project-items { 
    
}

tr td a.project-item-status {
    display: flex;
    justify-content: flex-end;
}

tr td a.project-item-status > span:first-child {
    display: inline-block;
    font-size: 14px;
}

.project-items > div {
    border: var(--light-border-color) 1px solid;
    padding: 16px;
    margin-bottom: 12px;
    border-radius: 8px;
}

.project-items > div.dragging { 
    opacity: 0.05;
}

ul.list-sections { 
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
}

ul.list-sections > li { 
    display: block;
    padding: 24px;
    background-color: var(--dark-background-color);
    border-radius: 12px;
    opacity: 0.75;
    margin-bottom: 32px;
}

ul.list-sections > li > a { 
    display: flex;
    justify-content: space-between;
    gap: 24px;
}

ul.list-sections > li > a > span.icon { 
}

ul.list-sections > li > a > span.icon > svg { 
    width: 32px;
    height: 32px;
    margin: 12px;
    padding-right: 12px;
}

ul.list-sections > li.over,
ul.list-sections > li:hover {
    opacity: 1;
}

ul.list-sections > li > a:hover > span.icon > svg,
ul.list-sections > li > a:hover > span.badge { 
    color: var(--bocg-blue);
}

ul.list-sections > li > a > span.content { 
    width: 100%;
}

ul.list-sections > li > a > span.badge {
    width: 128px;
    text-align: center;
    background-color: var(--dark-background-color);
    border-radius: 12px;
}

ul.list-sections > li > a > span.badge > label {
    font-size: 44px;
    padding: 8px;
    display: inline-block;
}

ul.list-sections > li > a > span.badge > label.med { 
    padding-top: 16px;
    display: inline-block;
    font-size: 28px;
}

ul.list-sections > li > a > span.badge > label.sm {
    padding-top: 24px;
    display: inline-block;
    font-size: 20px;
}

ul.list-sections > li > a > span > h4 { 
    margin-bottom: 12px;
    font-size: 20px;
    width: available;
}

ul.list-sections > li > a > span > p {
    margin-bottom: 0;
    margin-right: 0;
}

li.image-preview-thumbs { 
    background-color: var(--dark-background-color);
    padding: 16px;
    border-radius: 12px;
    margin-bottom: 16px;
}

li.image-preview-thumbs > div.thumbs { 
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    
    align-items: center;
    align-content: center;
    justify-content: flex-start;
}

.file-information { 
    
}

.details-body .file-information h3 {
    margin-bottom: 8px;
}

.details-body .file-information p { 
    margin-right: 12px;
    margin-bottom: 12px;
    max-width: unset;
    font-size: 14px;
}

.image-preview-thumb {
    border: #FFFFFF77 1px dashed;
    margin-right: 8px;
    position: relative;
    display: inline-block;
    margin-bottom: 12px;
    line-height: 0;
    text-align: center;
}

.image-preview-thumb > .label { 
    text-align: center;
    bottom: 16px;
    color: black;
    font-weight: bold;
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    margin: auto;
    padding: 12px;
    background-color: #FFFFFF55;
    z-index: 12;
    width: 100%;
}

.image-preview-thumb img { 
    object-fit: fill;
}

textarea.base64-code { 
    font-family: Courier New, Courier, monospace;
    min-width: 440px;
    min-height: 120px;
}

span.image-preview-thumb.no-image { 
    width: 180px;
    height: 180px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

span.image-preview-thumb > span { 
    display: inline-block;
    padding: 6px;
}

.details-body label.detail-footer { 
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    margin-top: 12px;
    margin-bottom: 48px;
}

p.condensed { 
    margin-bottom: 24px;
}

p.condensed.large {
    margin-bottom: 24px;
    margin-right: 0;
    min-width: 100%;
}

.form p.markdown ~ label.detail-footer { 
    padding-top: 0;
}

p.markdown ~ label.detail-footer { 
    margin-right: 0;
    margin-left: 12px;
}

.markdown ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
}

.markdown ol li {
    display: list-item;
    text-align: -webkit-match-parent;
    list-style: decimal;
}

label.detail-footer span.delete { 
    
}

label.detail-footer span.delete .action-button.delete { 
    border-radius: 6px;
}

.nav-history { 
    
}

.nav-history ul { 
    
}

.nav-history ul > li,
ul.nav-history > li {
    width: 280px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    
    background-color: var(--dark-background-color);
    padding: 8px;
}

.nav-history ul > li {
    width: 300px;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 12px 16px;
}

.user-menu > ul.nav-history > li { 
    border-bottom: var(--light-border-color) 1px solid;
}

.user-menu > ul.nav-history > li:last-child { 
    border-bottom: none;
}

.user-menu > ul.nav-history > li.header { 
    color: var(--text-color);
    font-weight: bold;
}

.MuiTooltip-tooltip .nav-history li > a:first-child {
    font-size: 14px;
}

.nav-history li > a { 
    color: var(--bocg-blue);
}

.nav-history ul > li > a:hover,
ul.nav-history > li > a:hover {
    color: var(--bright-text-color);
    opacity: 1.0;
}

.nav-history li > a.remove,
ul.nav-history li > a.remove {
    color: var(--warning-red);
    opacity: 0.1;
}

.nav-history li:hover > a.remove,
ul.nav-history li:hover > a.remove {
    opacity: 1.0;
}

select option.stage-option-4 { 
    color: var(--good-green);
}

.qr-code {
    background-color: yellow;
}

.qr-code.size128 {
    width: 128px;
    height: 128px;
}

.qr-code.size256 {
    width: 256px;
    height: 256px;
}

.qr-code.size512 {
    width: 512px;
    height: 512px;
}
