
.payment-form { 
    width: 600px;
}

.payment-form .form-group { 
}

.payment-form .form-group > select,
.payment-form .form-group > input[type="number"],
.payment-form .form-group > input[type="text"] {
    width: 600px;
}

.form .triple > div.minority.expiration-month {

}

.form .triple > div.minority.expiration-year {
    margin-right: 100px;
}

.form .triple > div.majority.cvv {

}

.form .form-group.triple > div.majority.cvv { 
    
}

.form .form-group.triple > div.majority.cvv > input { 
    width: 300px;
}

.payment-form .buttons { 
    margin-top: 24px;
}

h3.payment-method-header { 
    display: flex;
    justify-content: space-between;
    max-width: 600px;
}

h3.payment-method-header > span.view-selector { 
    font-size: 14px;
}

h3.payment-method-header > span.view-selector > span > a { 
    
}

input[type="number"].payment-amount { 
    font-size: 42px;
    color: var(--yellow);
    border-width: 0;
    width: 200px;
    max-width: unset;
    background-color: transparent;
    border-radius: 8px;
    padding-left: 16px;
}

input[type="number"].payment-amount:placeholder-shown {
    background-color: var(--input-background-color);
    padding-left: 8px;
}

input[type="number"].payment-amount:focus { 
    outline: none;
    background-color: transparent;
    padding-left: 16px;
}

input[type="number"].payment-amount:focus::placeholder { 
    color: transparent;
}

.form-group > span.currency { 
    display: block;
    position: relative;
}

.form-group > span.currency > .symbol {
    color: var(--yellow);
    font-size: 24px;
    font-weight: bold;
    position: absolute;
    z-index: 24;
    top: 6px;
    left: 0;
}

.form-group > span.currency > input[type="number"].payment-amount:empty:focus + .symbol {
    display: initial;
}

.form-group > span.currency > input[type="number"].payment-amount:focus + .symbol {
    display: initial;
}

.form-group > span.currency > input[type="number"].payment-amount:placeholder-shown + .symbol {
    display: none;
}

.details-body .flex.payment-customer-profile { 
    border-bottom: var(--border-color) 1px dashed;
    padding-bottom: 16px;
}

.details-body .flex.payment-customer-profile > div { 
    padding-right: 32px;
}