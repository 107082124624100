
.header-button a { 
    display: flex;
    justify-content: space-between;
}

.header-button > a > .header-button-icon { 
    display: inline-block;
}

.header-button > a > .header-button-label {
    display: inline-block;
    margin-left: 8px;
}

.header-button.working > a > .header-button-icon > svg {
    display: inline-block;
    animation: spin 0.5s linear infinite;
    width: 16px;
    height: 16px;
    max-height: 16px;
    max-width: 16px;
    min-width: 16px;
    min-height: 16px;
}
